import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Commercial1 from "../components/Commercial1";
import Commercial2 from "../components/Commercial2";
import Commercial3 from "../components/Commercial3";
import PreCheckout from "../components/PreCheckout";
import Checkout from "../components/Checkout";
import CommercialBG from "../images/new-slider-commercial.jpg";
import Favicon from "../images/logos/Gogreen-favicon.png"

const OffsetCommercial = () => {
  var carbonOffset = 20.0;
  var stateData = [
    [
      0,
      "Default",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
    [
      1,
      "Alabama",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.4,
      47.0,
      19.2,
      209.8,
      67.3,
      485.9,
      8.9,
      22.4,
      10.2,
      111.0,
      33.3,
      240.4,
      13.4,
      36.1,
      12.3,
      134.5,
      41.0,
      296.0,
      15.7,
      45.8,
      15.9,
      173.5,
      60.4,
      436.1,
      15.6,
      55.5,
      23.9,
      261.6,
      68.1,
      491.7,
      16.5,
      60.9,
      18.6,
      203.8,
      85.1,
      614.4,
      19.1,
      69.4,
      26.6,
      291.2,
      91.3,
      659.2,
      6.1,
      17.3,
      6.6,
      72.6,
      25.4,
      183.3,
      8.9,
      22.0,
      9.9,
      108.1,
      32.9,
      237.2,
      11.8,
      23.7,
      11.3,
      123.1,
      37.4,
      270.1,
      11.1,
      26.0,
      13.9,
      151.5,
      37.5,
      270.4,
      12.0,
      27.5,
      18.3,
      200.2,
      30.5,
      220.1,
      11.9,
      28.9,
      9.2,
      100.3,
      49.1,
      354.8,
      9.2,
      28.0,
      8.0,
      87.9,
      31.3,
      225.7,
      13.4,
      35.5,
      12.0,
      131.1,
      40.5,
      292.1,
      17.9,
      38.2,
      13.6,
      149.2,
      46.1,
      332.5,
      16.7,
      42.0,
      16.8,
      183.7,
      46.1,
      333.0,
      18.2,
      44.5,
      22.2,
      242.7,
      37.5,
      271.0,
      18.1,
      46.7,
      11.1,
      121.6,
      60.5,
      436.8,
      10.7,
      35.5,
      10.4,
      113.5,
      46.0,
      332.4,
      15.7,
      45.1,
      15.5,
      169.1,
      59.6,
      430.3,
      21.0,
      48.5,
      17.6,
      192.4,
      67.8,
      489.9,
      19.6,
      53.3,
      21.7,
      236.9,
      67.9,
      490.5,
      21.3,
      56.4,
      28.6,
      313.1,
      55.3,
      399.2,
      21.1,
      59.2,
      14.4,
      156.8,
      89.1,
      643.5,
      10.6,
      43.0,
      15.7,
      171.1,
      51.9,
      374.8,
      15.5,
      54.7,
      23.3,
      254.9,
      67.2,
      485.1,
      20.8,
      58.8,
      26.5,
      290.1,
      76.5,
      552.3,
      19.4,
      64.7,
      32.7,
      357.2,
      76.6,
      553.1,
      21.1,
      68.4,
      43.2,
      472.1,
      62.3,
      450.1,
      20.9,
      71.9,
      21.6,
      236.5,
      100.5,
      725.5,
      11.2,
      47.2,
      12.2,
      133.2,
      64.9,
      468.4,
      16.4,
      60.0,
      18.2,
      198.6,
      84.0,
      606.2,
      22.0,
      64.5,
      20.7,
      226.0,
      95.6,
      690.2,
      20.5,
      70.9,
      25.5,
      278.2,
      95.7,
      691.1,
      22.3,
      75.0,
      33.6,
      367.7,
      77.9,
      562.4,
      22.1,
      78.8,
      16.9,
      184.2,
      125.6,
      906.6,
      13.0,
      53.8,
      17.4,
      190.4,
      69.6,
      502.5,
      19.0,
      68.3,
      26.0,
      283.7,
      90.1,
      650.4,
      25.4,
      73.4,
      29.5,
      322.9,
      102.6,
      740.5,
      23.7,
      80.8,
      36.4,
      397.6,
      102.7,
      741.5,
      25.8,
      85.4,
      48.1,
      525.5,
      83.6,
      603.4,
      25.6,
      89.8,
      24.1,
      263.2,
      134.7,
      972.6,
    ],
    [
      2,
      "Alaska",

      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.8,
      76.2,
      35.1,
      383.6,
      73.4,
      530.0,
      5.4,
      36.3,
      18.6,
      202.9,
      36.3,
      262.2,
      8.2,
      58.5,
      22.5,
      245.9,
      44.7,
      322.9,
      9.6,
      74.2,
      29.0,
      317.2,
      65.9,
      475.6,
      9.5,
      90.0,
      43.8,
      478.3,
      74.3,
      536.3,
      10.1,
      98.7,
      34.1,
      372.5,
      92.8,
      670.1,
      11.7,
      112.5,
      48.7,
      532.4,
      99.6,
      719.0,
      3.7,
      28.1,
      12.1,
      132.6,
      27.7,
      199.9,
      5.4,
      35.7,
      18.1,
      197.7,
      35.8,
      258.7,
      7.3,
      38.4,
      20.6,
      225.0,
      40.8,
      294.6,
      6.8,
      42.2,
      25.3,
      277.0,
      40.9,
      295.0,
      7.4,
      44.7,
      33.5,
      366.1,
      33.2,
      240.0,
      7.3,
      46.9,
      16.8,
      183.4,
      53.6,
      386.9,
      5.6,
      45.4,
      14.7,
      160.8,
      34.1,
      246.1,
      8.2,
      57.6,
      21.9,
      239.6,
      44.1,
      318.5,
      11.0,
      62.0,
      25.0,
      272.7,
      50.2,
      362.7,
      10.3,
      68.2,
      30.7,
      335.8,
      50.3,
      363.2,
      11.2,
      72.1,
      40.6,
      443.7,
      40.9,
      295.5,
      11.1,
      75.8,
      20.3,
      222.3,
      66.0,
      476.4,
      6.6,
      57.5,
      19.0,
      207.4,
      50.2,
      362.6,
      9.6,
      73.1,
      28.3,
      309.1,
      65.0,
      469.3,
      12.8,
      78.6,
      32.2,
      351.8,
      74.0,
      534.3,
      12.0,
      86.4,
      39.6,
      433.1,
      74.1,
      535.0,
      13.1,
      91.4,
      52.4,
      572.4,
      60.3,
      435.3,
      13.0,
      96.1,
      26.2,
      286.7,
      97.2,
      701.8,
      6.5,
      69.8,
      28.6,
      312.7,
      56.6,
      408.8,
      9.5,
      88.6,
      42.6,
      466.0,
      73.3,
      529.1,
      12.7,
      95.3,
      48.5,
      530.4,
      83.4,
      602.4,
      11.9,
      104.8,
      59.8,
      653.0,
      83.5,
      603.2,
      12.9,
      110.9,
      79.0,
      863.0,
      68.0,
      490.8,
      12.8,
      116.5,
      39.6,
      432.3,
      109.6,
      791.2,
      6.9,
      76.5,
      22.3,
      243.6,
      70.7,
      510.8,
      10.1,
      97.2,
      33.2,
      363.0,
      91.6,
      661.2,
      13.5,
      104.6,
      37.8,
      413.1,
      104.3,
      752.8,
      12.6,
      115.0,
      46.5,
      508.7,
      104.4,
      753.8,
      13.7,
      121.6,
      61.5,
      672.2,
      85.0,
      613.4,
      13.6,
      127.8,
      30.8,
      336.7,
      136.9,
      988.8,
      8.0,
      87.2,
      31.9,
      348.1,
      75.9,
      548.0,
      11.7,
      110.7,
      47.5,
      518.7,
      98.2,
      709.3,
      15.6,
      119.1,
      54.0,
      590.4,
      111.9,
      807.6,
      14.6,
      130.9,
      66.5,
      726.9,
      112.0,
      808.7,
      15.8,
      138.5,
      87.9,
      960.7,
      91.1,
      658.1,
      15.7,
      145.5,
      44.0,
      481.2,
      146.9,
      1060.8,
    ],
    [
      3,
      "Arizona",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.6,
      30.0,
      22.9,
      250.3,
      67.3,
      485.9,
      4.7,
      14.3,
      12.1,
      132.4,
      33.3,
      240.4,
      7.1,
      23.0,
      14.7,
      160.4,
      41.0,
      296.0,
      8.3,
      29.2,
      18.9,
      207.0,
      60.4,
      436.1,
      8.2,
      35.4,
      28.6,
      312.0,
      68.1,
      491.7,
      8.7,
      38.9,
      22.2,
      243.1,
      85.1,
      614.4,
      10.0,
      44.3,
      31.8,
      347.3,
      91.3,
      659.2,
      3.2,
      11.1,
      7.9,
      86.5,
      25.4,
      183.3,
      4.7,
      14.1,
      11.8,
      129.0,
      32.9,
      237.2,
      6.2,
      15.1,
      13.4,
      146.8,
      37.4,
      270.1,
      5.8,
      16.6,
      16.5,
      180.7,
      37.5,
      270.4,
      6.3,
      17.6,
      21.9,
      238.8,
      30.5,
      220.1,
      6.3,
      18.5,
      10.9,
      119.6,
      49.1,
      354.8,
      4.8,
      17.9,
      9.6,
      104.9,
      31.3,
      225.7,
      7.1,
      22.7,
      14.3,
      156.3,
      40.5,
      292.1,
      9.4,
      24.4,
      16.3,
      177.9,
      46.1,
      332.5,
      8.8,
      26.8,
      20.0,
      219.1,
      46.1,
      333.0,
      9.6,
      28.4,
      26.5,
      289.5,
      37.5,
      271.0,
      9.5,
      29.8,
      13.3,
      145.0,
      60.5,
      436.8,
      5.7,
      22.6,
      12.4,
      135.3,
      46.0,
      332.4,
      8.2,
      28.8,
      18.5,
      201.7,
      59.6,
      430.3,
      11.0,
      30.9,
      21.0,
      229.5,
      67.8,
      489.9,
      10.3,
      34.0,
      25.9,
      282.6,
      67.9,
      490.5,
      11.2,
      36.0,
      34.2,
      373.5,
      55.3,
      399.2,
      11.1,
      37.8,
      17.1,
      187.1,
      89.1,
      643.5,
      5.6,
      27.5,
      18.7,
      204.0,
      51.9,
      374.8,
      8.2,
      34.9,
      27.8,
      304.0,
      67.2,
      485.1,
      10.9,
      37.5,
      31.7,
      346.0,
      76.5,
      552.3,
      10.2,
      41.3,
      39.0,
      426.1,
      76.6,
      553.1,
      11.1,
      43.7,
      51.5,
      563.1,
      62.3,
      450.1,
      11.0,
      45.9,
      25.8,
      282.0,
      100.5,
      725.5,
      5.9,
      30.1,
      14.5,
      158.9,
      64.9,
      468.4,
      8.6,
      38.3,
      21.7,
      236.8,
      84.0,
      606.2,
      11.6,
      41.2,
      24.7,
      269.5,
      95.6,
      690.2,
      10.8,
      45.3,
      30.4,
      331.9,
      95.7,
      691.1,
      11.7,
      47.9,
      40.1,
      438.6,
      77.9,
      562.4,
      11.6,
      50.3,
      20.1,
      219.7,
      125.6,
      906.6,
      6.9,
      34.3,
      20.8,
      227.1,
      69.6,
      502.5,
      10.0,
      43.6,
      31.0,
      338.4,
      90.1,
      650.4,
      13.4,
      46.9,
      35.2,
      385.2,
      102.6,
      740.5,
      12.5,
      51.6,
      43.4,
      474.2,
      102.7,
      741.5,
      13.6,
      54.5,
      57.3,
      626.8,
      83.6,
      603.4,
      13.5,
      57.3,
      28.7,
      313.9,
      134.7,
      972.6,
    ],
    [
      4,
      "Arkansas",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.4,
      47.0,
      19.2,
      209.8,
      67.3,
      485.9,
      8.9,
      22.4,
      10.2,
      111.0,
      33.3,
      240.4,
      13.4,
      36.1,
      12.3,
      134.5,
      41.0,
      296.0,
      15.7,
      45.8,
      15.9,
      173.5,
      60.4,
      436.1,
      15.6,
      55.5,
      23.9,
      261.6,
      68.1,
      491.7,
      16.5,
      60.9,
      18.6,
      203.8,
      85.1,
      614.4,
      19.1,
      69.4,
      26.6,
      291.2,
      91.3,
      659.2,
      6.1,
      17.3,
      6.6,
      72.6,
      25.4,
      183.3,
      8.9,
      22.0,
      9.9,
      108.1,
      32.9,
      237.2,
      11.8,
      23.7,
      11.3,
      123.1,
      37.4,
      270.1,
      11.1,
      26.0,
      13.9,
      151.5,
      37.5,
      270.4,
      12.0,
      27.5,
      18.3,
      200.2,
      30.5,
      220.1,
      11.9,
      28.9,
      9.2,
      100.3,
      49.1,
      354.8,
      9.2,
      28.0,
      8.0,
      87.9,
      31.3,
      225.7,
      13.4,
      35.5,
      12.0,
      131.1,
      40.5,
      292.1,
      17.9,
      38.2,
      13.6,
      149.2,
      46.1,
      332.5,
      16.7,
      42.0,
      16.8,
      183.7,
      46.1,
      333.0,
      18.2,
      44.5,
      22.2,
      242.7,
      37.5,
      271.0,
      18.1,
      46.7,
      11.1,
      121.6,
      60.5,
      436.8,
      10.7,
      35.5,
      10.4,
      113.5,
      46.0,
      332.4,
      15.7,
      45.1,
      15.5,
      169.1,
      59.6,
      430.3,
      21.0,
      48.5,
      17.6,
      192.4,
      67.8,
      489.9,
      19.6,
      53.3,
      21.7,
      236.9,
      67.9,
      490.5,
      21.3,
      56.4,
      28.6,
      313.1,
      55.3,
      399.2,
      21.1,
      59.2,
      14.4,
      156.8,
      89.1,
      643.5,
      10.6,
      43.0,
      15.7,
      171.1,
      51.9,
      374.8,
      15.5,
      54.7,
      23.3,
      254.9,
      67.2,
      485.1,
      20.8,
      58.8,
      26.5,
      290.1,
      76.5,
      552.3,
      19.4,
      64.7,
      32.7,
      357.2,
      76.6,
      553.1,
      21.1,
      68.4,
      43.2,
      472.1,
      62.3,
      450.1,
      20.9,
      71.9,
      21.6,
      236.5,
      100.5,
      725.5,
      11.2,
      47.2,
      12.2,
      133.2,
      64.9,
      468.4,
      16.4,
      60.0,
      18.2,
      198.6,
      84.0,
      606.2,
      22.0,
      64.5,
      20.7,
      226.0,
      95.6,
      690.2,
      20.5,
      70.9,
      25.5,
      278.2,
      95.7,
      691.1,
      22.3,
      75.0,
      33.6,
      367.7,
      77.9,
      562.4,
      22.1,
      78.8,
      16.9,
      184.2,
      125.6,
      906.6,
      13.0,
      53.8,
      17.4,
      190.4,
      69.6,
      502.5,
      19.0,
      68.3,
      26.0,
      283.7,
      90.1,
      650.4,
      25.4,
      73.4,
      29.5,
      322.9,
      102.6,
      740.5,
      23.7,
      80.8,
      36.4,
      397.6,
      102.7,
      741.5,
      25.8,
      85.4,
      48.1,
      525.5,
      83.6,
      603.4,
      25.6,
      89.8,
      24.1,
      263.2,
      134.7,
      972.6,
    ],
    [
      5,
      "California",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.0,
      32.4,
      24.0,
      262.3,
      59.2,
      427.4,
      4.9,
      15.4,
      12.7,
      138.7,
      29.3,
      211.5,
      7.5,
      24.9,
      15.4,
      168.1,
      36.1,
      260.4,
      8.7,
      31.6,
      19.8,
      216.9,
      53.1,
      383.6,
      8.7,
      38.3,
      29.9,
      327.0,
      59.9,
      432.5,
      9.2,
      42.0,
      23.3,
      254.7,
      74.9,
      540.5,
      10.6,
      47.8,
      33.3,
      364.0,
      80.3,
      579.9,
      3.4,
      11.9,
      8.3,
      90.7,
      22.3,
      161.2,
      4.9,
      15.2,
      12.4,
      135.2,
      28.9,
      208.7,
      6.6,
      16.3,
      14.1,
      153.8,
      32.9,
      237.6,
      6.2,
      17.9,
      17.3,
      189.4,
      32.9,
      237.9,
      6.7,
      19.0,
      22.9,
      250.3,
      26.8,
      193.6,
      6.6,
      19.9,
      11.5,
      125.4,
      43.2,
      312.1,
      5.1,
      19.3,
      10.1,
      109.9,
      27.5,
      198.5,
      7.5,
      24.5,
      15.0,
      163.8,
      35.6,
      256.9,
      10.0,
      26.4,
      17.1,
      186.5,
      40.5,
      292.5,
      9.3,
      29.0,
      21.0,
      229.6,
      40.6,
      292.9,
      10.1,
      30.7,
      27.8,
      303.4,
      33.0,
      238.3,
      10.1,
      32.2,
      13.9,
      152.0,
      53.2,
      384.2,
      6.0,
      24.5,
      13.0,
      141.8,
      40.5,
      292.4,
      8.7,
      31.1,
      19.3,
      211.3,
      52.4,
      378.5,
      11.7,
      33.4,
      22.0,
      240.5,
      59.7,
      430.9,
      10.9,
      36.7,
      27.1,
      296.1,
      59.8,
      431.5,
      11.9,
      38.9,
      35.8,
      391.4,
      48.6,
      351.1,
      11.8,
      40.8,
      17.9,
      196.0,
      78.4,
      566.0,
      5.9,
      29.7,
      19.6,
      213.8,
      45.7,
      329.7,
      8.6,
      37.7,
      29.2,
      318.6,
      59.1,
      426.7,
      11.5,
      40.5,
      33.2,
      362.7,
      67.3,
      485.9,
      10.8,
      44.6,
      40.9,
      446.5,
      67.4,
      486.5,
      11.7,
      47.2,
      54.0,
      590.1,
      54.8,
      395.9,
      11.6,
      49.5,
      27.0,
      295.6,
      88.4,
      638.2,
      6.3,
      32.5,
      15.2,
      166.6,
      57.1,
      412.0,
      9.1,
      41.3,
      22.7,
      248.2,
      73.9,
      533.3,
      12.2,
      44.5,
      25.8,
      282.5,
      84.1,
      607.1,
      11.4,
      48.9,
      31.8,
      347.8,
      84.2,
      607.9,
      12.4,
      51.7,
      42.1,
      459.7,
      68.5,
      494.7,
      12.3,
      54.3,
      21.1,
      230.2,
      110.5,
      797.5,
      7.2,
      37.1,
      21.8,
      238.0,
      61.2,
      442.0,
      10.6,
      47.1,
      32.5,
      354.7,
      79.2,
      572.1,
      14.1,
      50.6,
      36.9,
      403.7,
      90.2,
      651.4,
      13.2,
      55.7,
      45.5,
      497.0,
      90.3,
      652.2,
      14.4,
      58.9,
      60.1,
      656.9,
      73.5,
      530.8,
      14.3,
      61.9,
      30.1,
      329.0,
      118.5,
      855.6,
    ],
    [
      6,
      "Colorado",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.3,
      43.0,
      25.4,
      277.6,
      67.3,
      485.9,
      6.4,
      20.5,
      13.4,
      146.8,
      33.3,
      240.4,
      9.6,
      33.0,
      16.3,
      177.9,
      41.0,
      296.0,
      11.3,
      41.9,
      21.0,
      229.6,
      60.4,
      436.1,
      11.2,
      50.8,
      31.7,
      346.1,
      68.1,
      491.7,
      11.8,
      55.7,
      24.7,
      269.6,
      85.1,
      614.4,
      13.7,
      63.5,
      35.3,
      385.3,
      91.3,
      659.2,
      4.4,
      15.9,
      8.8,
      96.0,
      25.4,
      183.3,
      6.4,
      20.1,
      13.1,
      143.0,
      32.9,
      237.2,
      8.5,
      21.7,
      14.9,
      162.8,
      37.4,
      270.1,
      7.9,
      23.8,
      18.3,
      200.4,
      37.5,
      270.4,
      8.6,
      25.2,
      24.2,
      264.9,
      30.5,
      220.1,
      8.6,
      26.5,
      12.1,
      132.7,
      49.1,
      354.8,
      6.6,
      25.6,
      10.6,
      116.3,
      31.3,
      225.7,
      9.6,
      32.5,
      15.9,
      173.4,
      40.5,
      292.1,
      12.9,
      35.0,
      18.1,
      197.3,
      46.1,
      332.5,
      12.0,
      38.5,
      22.2,
      243.0,
      46.1,
      333.0,
      13.1,
      40.7,
      29.4,
      321.1,
      37.5,
      271.0,
      13.0,
      42.7,
      14.7,
      160.8,
      60.5,
      436.8,
      7.7,
      32.5,
      13.7,
      150.1,
      46.0,
      332.4,
      11.3,
      41.2,
      20.5,
      223.7,
      59.6,
      430.3,
      15.0,
      44.3,
      23.3,
      254.6,
      67.8,
      489.9,
      14.1,
      48.8,
      28.7,
      313.4,
      67.9,
      490.5,
      15.3,
      51.6,
      37.9,
      414.2,
      55.3,
      399.2,
      15.2,
      54.2,
      19.0,
      207.5,
      89.1,
      643.5,
      7.6,
      39.4,
      20.7,
      226.3,
      51.9,
      374.8,
      11.1,
      50.0,
      30.9,
      337.2,
      67.2,
      485.1,
      14.9,
      53.8,
      35.1,
      383.8,
      76.5,
      552.3,
      13.9,
      59.2,
      43.2,
      472.6,
      76.6,
      553.1,
      15.1,
      62.6,
      57.1,
      624.5,
      62.3,
      450.1,
      15.0,
      65.8,
      28.6,
      312.8,
      100.5,
      725.5,
      8.1,
      43.2,
      16.1,
      176.3,
      64.9,
      468.4,
      11.8,
      54.9,
      24.0,
      262.7,
      84.0,
      606.2,
      15.8,
      59.0,
      27.4,
      299.0,
      95.6,
      690.2,
      14.7,
      64.9,
      33.7,
      368.1,
      95.7,
      691.1,
      16.0,
      68.6,
      44.5,
      486.5,
      77.9,
      562.4,
      15.9,
      72.1,
      22.3,
      243.7,
      125.6,
      906.6,
      9.3,
      49.2,
      23.0,
      251.9,
      69.6,
      502.5,
      13.6,
      62.5,
      34.3,
      375.4,
      90.1,
      650.4,
      18.2,
      67.2,
      39.1,
      427.2,
      102.6,
      740.5,
      17.0,
      73.9,
      48.1,
      526.0,
      102.7,
      741.5,
      18.5,
      78.2,
      63.6,
      695.2,
      83.6,
      603.4,
      18.4,
      82.1,
      31.9,
      348.2,
      134.7,
      972.6,
    ],
    [
      7,
      "Connecticut",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      8,
      "Delaware",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      9,
      "District of Columbia",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      10,
      "Florida",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      13.7,
      35.6,
      13.5,
      147.5,
      67.3,
      485.9,
      8.4,
      16.9,
      7.1,
      78.0,
      33.3,
      240.4,
      12.8,
      27.3,
      8.7,
      94.6,
      41.0,
      296.0,
      14.9,
      34.7,
      11.2,
      122.0,
      60.4,
      436.1,
      14.8,
      42.1,
      16.8,
      184.0,
      68.1,
      491.7,
      15.6,
      46.1,
      13.1,
      143.3,
      85.1,
      614.4,
      18.1,
      52.5,
      18.7,
      204.8,
      91.3,
      659.2,
      5.8,
      13.1,
      4.7,
      51.0,
      25.4,
      183.3,
      8.4,
      16.7,
      7.0,
      76.0,
      32.9,
      237.2,
      11.2,
      17.9,
      7.9,
      86.5,
      37.4,
      270.1,
      10.5,
      19.7,
      9.7,
      106.5,
      37.5,
      270.4,
      11.4,
      20.9,
      12.9,
      140.8,
      30.5,
      220.1,
      11.3,
      21.9,
      6.5,
      70.5,
      49.1,
      354.8,
      8.7,
      21.2,
      5.7,
      61.8,
      31.3,
      225.7,
      12.7,
      26.9,
      8.4,
      92.2,
      40.5,
      292.1,
      17.0,
      29.0,
      9.6,
      104.9,
      46.1,
      332.5,
      15.9,
      31.8,
      11.8,
      129.1,
      46.1,
      333.0,
      17.3,
      33.7,
      15.6,
      170.7,
      37.5,
      271.0,
      17.2,
      35.4,
      7.8,
      85.5,
      60.5,
      436.8,
      10.2,
      26.9,
      7.3,
      79.8,
      46.0,
      332.4,
      14.9,
      34.1,
      10.9,
      118.9,
      59.6,
      430.3,
      19.9,
      36.7,
      12.4,
      135.3,
      67.8,
      489.9,
      18.6,
      40.4,
      15.2,
      166.6,
      67.9,
      490.5,
      20.2,
      42.7,
      20.1,
      220.2,
      55.3,
      399.2,
      20.1,
      44.9,
      10.1,
      110.3,
      89.1,
      643.5,
      10.1,
      32.6,
      11.0,
      120.3,
      51.9,
      374.8,
      14.7,
      41.4,
      16.4,
      179.2,
      67.2,
      485.1,
      19.7,
      44.5,
      18.7,
      204.0,
      76.5,
      552.3,
      18.4,
      49.0,
      23.0,
      251.2,
      76.6,
      553.1,
      20.0,
      51.8,
      30.4,
      331.9,
      62.3,
      450.1,
      19.9,
      54.4,
      15.2,
      166.3,
      100.5,
      725.5,
      10.7,
      35.8,
      8.6,
      93.7,
      64.9,
      468.4,
      15.6,
      45.4,
      12.8,
      139.6,
      84.0,
      606.2,
      20.8,
      48.8,
      14.5,
      158.9,
      95.6,
      690.2,
      19.5,
      53.7,
      17.9,
      195.6,
      95.7,
      691.1,
      21.2,
      56.8,
      23.7,
      258.6,
      77.9,
      562.4,
      21.0,
      59.7,
      11.8,
      129.5,
      125.6,
      906.6,
      12.4,
      40.7,
      12.3,
      133.9,
      69.6,
      502.5,
      18.1,
      51.7,
      18.3,
      199.5,
      90.1,
      650.4,
      24.1,
      55.6,
      20.8,
      227.1,
      102.6,
      740.5,
      22.6,
      61.2,
      25.6,
      279.6,
      102.7,
      741.5,
      24.5,
      64.7,
      33.8,
      369.5,
      83.6,
      603.4,
      24.3,
      68.0,
      16.9,
      185.1,
      134.7,
      972.6,
    ],
    [
      11,
      "Georgia",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      13.3,
      50.1,
      28.5,
      311.5,
      47.6,
      343.7,
      8.2,
      23.8,
      15.1,
      164.7,
      23.6,
      170.1,
      12.4,
      38.5,
      18.3,
      199.7,
      29.0,
      209.4,
      14.5,
      48.8,
      23.6,
      257.6,
      42.7,
      308.4,
      14.4,
      59.2,
      35.5,
      388.3,
      48.2,
      347.8,
      15.2,
      64.9,
      27.7,
      302.5,
      60.2,
      434.6,
      17.6,
      73.9,
      39.6,
      432.3,
      64.6,
      466.2,
      5.6,
      18.5,
      9.9,
      107.7,
      18.0,
      129.6,
      8.2,
      23.5,
      14.7,
      160.5,
      23.2,
      167.8,
      11.0,
      25.2,
      16.7,
      182.7,
      26.5,
      191.0,
      10.2,
      27.8,
      20.6,
      224.9,
      26.5,
      191.3,
      11.1,
      29.4,
      27.2,
      297.2,
      21.6,
      155.7,
      11.0,
      30.8,
      13.6,
      148.9,
      34.8,
      250.9,
      8.5,
      29.8,
      11.9,
      130.5,
      22.1,
      159.6,
      12.4,
      37.9,
      17.8,
      194.5,
      28.6,
      206.6,
      16.6,
      40.7,
      20.3,
      221.4,
      32.6,
      235.2,
      15.5,
      44.8,
      24.9,
      272.6,
      32.6,
      235.5,
      16.9,
      47.4,
      33.0,
      360.3,
      26.5,
      191.6,
      16.7,
      49.8,
      16.5,
      180.5,
      42.8,
      308.9,
      9.9,
      37.8,
      15.4,
      168.4,
      32.6,
      235.1,
      14.5,
      48.0,
      23.0,
      251.0,
      42.1,
      304.3,
      19.4,
      51.7,
      26.1,
      285.6,
      48.0,
      346.5,
      18.1,
      56.8,
      32.2,
      351.7,
      48.1,
      346.9,
      19.7,
      60.1,
      42.5,
      464.8,
      39.1,
      282.3,
      19.6,
      63.2,
      21.3,
      232.8,
      63.0,
      455.1,
      9.8,
      45.9,
      23.2,
      253.9,
      36.7,
      265.1,
      14.4,
      58.3,
      34.6,
      378.4,
      47.5,
      343.1,
      19.2,
      62.7,
      39.4,
      430.7,
      54.1,
      390.7,
      17.9,
      68.9,
      48.5,
      530.2,
      54.2,
      391.2,
      19.5,
      72.9,
      64.1,
      700.8,
      44.1,
      318.3,
      19.4,
      76.6,
      32.1,
      351.0,
      71.1,
      513.1,
      10.4,
      50.3,
      18.1,
      197.8,
      45.9,
      331.3,
      15.2,
      63.9,
      27.0,
      294.7,
      59.4,
      428.8,
      20.3,
      68.7,
      30.7,
      335.5,
      67.6,
      488.2,
      19.0,
      75.6,
      37.8,
      413.0,
      67.7,
      488.8,
      20.7,
      80.0,
      49.9,
      545.8,
      55.1,
      397.8,
      20.5,
      84.0,
      25.0,
      273.4,
      88.8,
      641.2,
      12.0,
      57.3,
      25.9,
      282.6,
      49.2,
      355.4,
      17.6,
      72.8,
      38.5,
      421.2,
      63.7,
      460.0,
      23.5,
      78.3,
      43.9,
      479.4,
      72.5,
      523.7,
      22.0,
      86.1,
      54.0,
      590.2,
      72.6,
      524.4,
      23.9,
      91.1,
      71.4,
      780.0,
      59.1,
      426.8,
      23.7,
      95.7,
      35.7,
      390.7,
      95.3,
      687.9,
    ],
    [
      12,
      "Hawaii",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.0,
      32.4,
      24.0,
      262.3,
      59.2,
      427.4,
      4.9,
      15.4,
      12.7,
      138.7,
      29.3,
      211.5,
      7.5,
      24.9,
      15.4,
      168.1,
      36.1,
      260.4,
      8.7,
      31.6,
      19.8,
      216.9,
      53.1,
      383.6,
      8.7,
      38.3,
      29.9,
      327.0,
      59.9,
      432.5,
      9.2,
      42.0,
      23.3,
      254.7,
      74.9,
      540.5,
      10.6,
      47.8,
      33.3,
      364.0,
      80.3,
      579.9,
      3.4,
      11.9,
      8.3,
      90.7,
      22.3,
      161.2,
      4.9,
      15.2,
      12.4,
      135.2,
      28.9,
      208.7,
      6.6,
      16.3,
      14.1,
      153.8,
      32.9,
      237.6,
      6.2,
      17.9,
      17.3,
      189.4,
      32.9,
      237.9,
      6.7,
      19.0,
      22.9,
      250.3,
      26.8,
      193.6,
      6.6,
      19.9,
      11.5,
      125.4,
      43.2,
      312.1,
      5.1,
      19.3,
      10.1,
      109.9,
      27.5,
      198.5,
      7.5,
      24.5,
      15.0,
      163.8,
      35.6,
      256.9,
      10.0,
      26.4,
      17.1,
      186.5,
      40.5,
      292.5,
      9.3,
      29.0,
      21.0,
      229.6,
      40.6,
      292.9,
      10.1,
      30.7,
      27.8,
      303.4,
      33.0,
      238.3,
      10.1,
      32.2,
      13.9,
      152.0,
      53.2,
      384.2,
      6.0,
      24.5,
      13.0,
      141.8,
      40.5,
      292.4,
      8.7,
      31.1,
      19.3,
      211.3,
      52.4,
      378.5,
      11.7,
      33.4,
      22.0,
      240.5,
      59.7,
      430.9,
      10.9,
      36.7,
      27.1,
      296.1,
      59.8,
      431.5,
      11.9,
      38.9,
      35.8,
      391.4,
      48.6,
      351.1,
      11.8,
      40.8,
      17.9,
      196.0,
      78.4,
      566.0,
      5.9,
      29.7,
      19.6,
      213.8,
      45.7,
      329.7,
      8.6,
      37.7,
      29.2,
      318.6,
      59.1,
      426.7,
      11.5,
      40.5,
      33.2,
      362.7,
      67.3,
      485.9,
      10.8,
      44.6,
      40.9,
      446.5,
      67.4,
      486.5,
      11.7,
      47.2,
      54.0,
      590.1,
      54.8,
      395.9,
      11.6,
      49.5,
      27.0,
      295.6,
      88.4,
      638.2,
      6.3,
      32.5,
      15.2,
      166.6,
      57.1,
      412.0,
      9.1,
      41.3,
      22.7,
      248.2,
      73.9,
      533.3,
      12.2,
      44.5,
      25.8,
      282.5,
      84.1,
      607.1,
      11.4,
      48.9,
      31.8,
      347.8,
      84.2,
      607.9,
      12.4,
      51.7,
      42.1,
      459.7,
      68.5,
      494.7,
      12.3,
      54.3,
      21.1,
      230.2,
      110.5,
      797.5,
      7.2,
      37.1,
      21.8,
      238.0,
      61.2,
      442.0,
      10.6,
      47.1,
      32.5,
      354.7,
      79.2,
      572.1,
      14.1,
      50.6,
      36.9,
      403.7,
      90.2,
      651.4,
      13.2,
      55.7,
      45.5,
      497.0,
      90.3,
      652.2,
      14.4,
      58.9,
      60.1,
      656.9,
      73.5,
      530.8,
      14.3,
      61.9,
      30.1,
      329.0,
      118.5,
      855.6,
    ],
    [
      13,
      "Idaho",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.3,
      65.2,
      44.6,
      487.4,
      67.3,
      485.9,
      5.1,
      31.0,
      23.6,
      257.8,
      33.3,
      240.4,
      7.8,
      50.1,
      28.6,
      312.5,
      41.0,
      296.0,
      9.1,
      63.5,
      36.9,
      403.1,
      60.4,
      436.1,
      9.0,
      77.0,
      55.6,
      607.7,
      68.1,
      491.7,
      9.5,
      84.5,
      43.3,
      473.4,
      85.1,
      614.4,
      11.0,
      96.2,
      61.9,
      676.5,
      91.3,
      659.2,
      3.5,
      24.0,
      15.4,
      168.5,
      25.4,
      183.3,
      5.1,
      30.5,
      23.0,
      251.2,
      32.9,
      237.2,
      6.8,
      32.8,
      26.2,
      285.9,
      37.4,
      270.1,
      6.4,
      36.1,
      32.2,
      352.0,
      37.5,
      270.4,
      6.9,
      38.2,
      42.6,
      465.2,
      30.5,
      220.1,
      6.9,
      40.1,
      21.3,
      233.0,
      49.1,
      354.8,
      5.3,
      38.8,
      18.7,
      204.3,
      31.3,
      225.7,
      7.7,
      49.3,
      27.9,
      304.4,
      40.5,
      292.1,
      10.3,
      53.0,
      31.7,
      346.5,
      46.1,
      332.5,
      9.7,
      58.3,
      39.0,
      426.6,
      46.1,
      333.0,
      10.5,
      61.7,
      51.6,
      563.8,
      37.5,
      271.0,
      10.4,
      64.8,
      25.8,
      282.4,
      60.5,
      436.8,
      6.2,
      49.2,
      24.1,
      263.5,
      46.0,
      332.4,
      9.0,
      62.5,
      35.9,
      392.7,
      59.6,
      430.3,
      12.1,
      67.2,
      40.9,
      447.0,
      67.8,
      489.9,
      11.3,
      73.9,
      50.4,
      550.3,
      67.9,
      490.5,
      12.3,
      78.2,
      66.6,
      727.3,
      55.3,
      399.2,
      12.2,
      82.2,
      33.3,
      364.3,
      89.1,
      643.5,
      6.1,
      59.7,
      36.4,
      397.4,
      51.9,
      374.8,
      8.9,
      75.8,
      54.2,
      592.1,
      67.2,
      485.1,
      12.0,
      81.6,
      61.7,
      674.0,
      76.5,
      552.3,
      11.2,
      89.7,
      75.9,
      829.8,
      76.6,
      553.1,
      12.2,
      94.9,
      100.3,
      1096.6,
      62.3,
      450.1,
      12.1,
      99.7,
      50.3,
      549.3,
      100.5,
      725.5,
      6.5,
      65.5,
      28.3,
      309.5,
      64.9,
      468.4,
      9.5,
      83.2,
      42.2,
      461.2,
      84.0,
      606.2,
      12.7,
      89.5,
      48.0,
      525.0,
      95.6,
      690.2,
      11.8,
      98.4,
      59.1,
      646.3,
      95.7,
      691.1,
      12.9,
      104.1,
      78.2,
      854.2,
      77.9,
      562.4,
      12.8,
      109.3,
      39.1,
      427.9,
      125.6,
      906.6,
      7.5,
      74.6,
      40.5,
      442.3,
      69.6,
      502.5,
      11.0,
      94.7,
      60.3,
      659.1,
      90.1,
      650.4,
      14.7,
      101.9,
      68.6,
      750.2,
      102.6,
      740.5,
      13.7,
      112.0,
      84.5,
      923.6,
      102.7,
      741.5,
      14.9,
      118.5,
      111.7,
      1220.7,
      83.6,
      603.4,
      14.8,
      124.5,
      55.9,
      611.4,
      134.7,
      972.6,
    ],
    [
      14,
      "Illinois",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      15,
      "Indiana",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      9.0,
      81.1,
      50.1,
      547.5,
      67.3,
      485.9,
      5.6,
      38.6,
      26.5,
      289.6,
      33.3,
      240.4,
      8.4,
      62.3,
      32.1,
      351.0,
      41.0,
      296.0,
      9.9,
      79.0,
      41.4,
      452.8,
      60.4,
      436.1,
      9.8,
      95.8,
      62.5,
      682.7,
      68.1,
      491.7,
      10.3,
      105.1,
      48.7,
      531.7,
      85.1,
      614.4,
      12.0,
      119.7,
      69.5,
      759.9,
      91.3,
      659.2,
      3.8,
      29.9,
      17.3,
      189.3,
      25.4,
      183.3,
      5.6,
      38.0,
      25.8,
      282.1,
      32.9,
      237.2,
      7.4,
      40.9,
      29.4,
      321.1,
      37.4,
      270.1,
      6.9,
      44.9,
      36.2,
      395.4,
      37.5,
      270.4,
      7.5,
      47.5,
      47.8,
      522.5,
      30.5,
      220.1,
      7.5,
      49.9,
      23.9,
      261.7,
      49.1,
      354.8,
      5.8,
      48.3,
      21.0,
      229.5,
      31.3,
      225.7,
      8.4,
      61.3,
      31.3,
      342.0,
      40.5,
      292.1,
      11.2,
      66.0,
      35.6,
      389.2,
      46.1,
      332.5,
      10.5,
      72.5,
      43.8,
      479.2,
      46.1,
      333.0,
      11.4,
      76.7,
      58.0,
      633.4,
      37.5,
      271.0,
      11.3,
      80.6,
      29.0,
      317.2,
      60.5,
      436.8,
      6.7,
      61.2,
      27.1,
      296.0,
      46.0,
      332.4,
      9.8,
      77.8,
      40.4,
      441.2,
      59.6,
      430.3,
      13.1,
      83.6,
      45.9,
      502.1,
      67.8,
      489.9,
      12.3,
      92.0,
      56.6,
      618.2,
      67.9,
      490.5,
      13.4,
      97.3,
      74.8,
      817.0,
      55.3,
      399.2,
      13.3,
      102.2,
      37.4,
      409.2,
      89.1,
      643.5,
      6.7,
      74.3,
      40.8,
      446.4,
      51.9,
      374.8,
      9.7,
      94.3,
      60.9,
      665.2,
      67.2,
      485.1,
      13.0,
      101.5,
      69.3,
      757.1,
      76.5,
      552.3,
      12.2,
      111.6,
      85.3,
      932.1,
      76.6,
      553.1,
      13.2,
      118.0,
      112.7,
      1231.9,
      62.3,
      450.1,
      13.1,
      124.0,
      56.5,
      617.0,
      100.5,
      725.5,
      7.1,
      81.5,
      31.8,
      347.7,
      64.9,
      468.4,
      10.3,
      103.5,
      47.4,
      518.1,
      84.0,
      606.2,
      13.8,
      111.3,
      54.0,
      589.7,
      95.6,
      690.2,
      12.9,
      122.4,
      66.4,
      726.0,
      95.7,
      691.1,
      14.0,
      129.5,
      87.8,
      959.5,
      77.9,
      562.4,
      13.9,
      136.0,
      44.0,
      480.6,
      125.6,
      906.6,
      8.2,
      92.8,
      45.5,
      496.9,
      69.6,
      502.5,
      11.9,
      117.8,
      67.7,
      740.4,
      90.1,
      650.4,
      15.9,
      126.7,
      77.1,
      842.7,
      102.6,
      740.5,
      14.9,
      139.4,
      94.9,
      1037.5,
      102.7,
      741.5,
      16.2,
      147.4,
      125.5,
      1371.2,
      83.6,
      603.4,
      16.1,
      154.9,
      62.8,
      686.8,
      134.7,
      972.6,
    ],
    [
      16,
      "Iowa",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      17,
      "Kansas",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      18,
      "Kentucky",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.4,
      47.0,
      19.2,
      209.8,
      67.3,
      485.9,
      8.9,
      22.4,
      10.2,
      111.0,
      33.3,
      240.4,
      13.4,
      36.1,
      12.3,
      134.5,
      41.0,
      296.0,
      15.7,
      45.8,
      15.9,
      173.5,
      60.4,
      436.1,
      15.6,
      55.5,
      23.9,
      261.6,
      68.1,
      491.7,
      16.5,
      60.9,
      18.6,
      203.8,
      85.1,
      614.4,
      19.1,
      69.4,
      26.6,
      291.2,
      91.3,
      659.2,
      6.1,
      17.3,
      6.6,
      72.6,
      25.4,
      183.3,
      8.9,
      22.0,
      9.9,
      108.1,
      32.9,
      237.2,
      11.8,
      23.7,
      11.3,
      123.1,
      37.4,
      270.1,
      11.1,
      26.0,
      13.9,
      151.5,
      37.5,
      270.4,
      12.0,
      27.5,
      18.3,
      200.2,
      30.5,
      220.1,
      11.9,
      28.9,
      9.2,
      100.3,
      49.1,
      354.8,
      9.2,
      28.0,
      8.0,
      87.9,
      31.3,
      225.7,
      13.4,
      35.5,
      12.0,
      131.1,
      40.5,
      292.1,
      17.9,
      38.2,
      13.6,
      149.2,
      46.1,
      332.5,
      16.7,
      42.0,
      16.8,
      183.7,
      46.1,
      333.0,
      18.2,
      44.5,
      22.2,
      242.7,
      37.5,
      271.0,
      18.1,
      46.7,
      11.1,
      121.6,
      60.5,
      436.8,
      10.7,
      35.5,
      10.4,
      113.5,
      46.0,
      332.4,
      15.7,
      45.1,
      15.5,
      169.1,
      59.6,
      430.3,
      21.0,
      48.5,
      17.6,
      192.4,
      67.8,
      489.9,
      19.6,
      53.3,
      21.7,
      236.9,
      67.9,
      490.5,
      21.3,
      56.4,
      28.6,
      313.1,
      55.3,
      399.2,
      21.1,
      59.2,
      14.4,
      156.8,
      89.1,
      643.5,
      10.6,
      43.0,
      15.7,
      171.1,
      51.9,
      374.8,
      15.5,
      54.7,
      23.3,
      254.9,
      67.2,
      485.1,
      20.8,
      58.8,
      26.5,
      290.1,
      76.5,
      552.3,
      19.4,
      64.7,
      32.7,
      357.2,
      76.6,
      553.1,
      21.1,
      68.4,
      43.2,
      472.1,
      62.3,
      450.1,
      20.9,
      71.9,
      21.6,
      236.5,
      100.5,
      725.5,
      11.2,
      47.2,
      12.2,
      133.2,
      64.9,
      468.4,
      16.4,
      60.0,
      18.2,
      198.6,
      84.0,
      606.2,
      22.0,
      64.5,
      20.7,
      226.0,
      95.6,
      690.2,
      20.5,
      70.9,
      25.5,
      278.2,
      95.7,
      691.1,
      22.3,
      75.0,
      33.6,
      367.7,
      77.9,
      562.4,
      22.1,
      78.8,
      16.9,
      184.2,
      125.6,
      906.6,
      13.0,
      53.8,
      17.4,
      190.4,
      69.6,
      502.5,
      19.0,
      68.3,
      26.0,
      283.7,
      90.1,
      650.4,
      25.4,
      73.4,
      29.5,
      322.9,
      102.6,
      740.5,
      23.7,
      80.8,
      36.4,
      397.6,
      102.7,
      741.5,
      25.8,
      85.4,
      48.1,
      525.5,
      83.6,
      603.4,
      25.6,
      89.8,
      24.1,
      263.2,
      134.7,
      972.6,
    ],
    [
      19,
      "Louisiana",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      13.7,
      35.6,
      13.5,
      147.5,
      67.3,
      485.9,
      8.4,
      16.9,
      7.1,
      78.0,
      33.3,
      240.4,
      12.8,
      27.3,
      8.7,
      94.6,
      41.0,
      296.0,
      14.9,
      34.7,
      11.2,
      122.0,
      60.4,
      436.1,
      14.8,
      42.1,
      16.8,
      184.0,
      68.1,
      491.7,
      15.6,
      46.1,
      13.1,
      143.3,
      85.1,
      614.4,
      18.1,
      52.5,
      18.7,
      204.8,
      91.3,
      659.2,
      5.8,
      13.1,
      4.7,
      51.0,
      25.4,
      183.3,
      8.4,
      16.7,
      7.0,
      76.0,
      32.9,
      237.2,
      11.2,
      17.9,
      7.9,
      86.5,
      37.4,
      270.1,
      10.5,
      19.7,
      9.7,
      106.5,
      37.5,
      270.4,
      11.4,
      20.9,
      12.9,
      140.8,
      30.5,
      220.1,
      11.3,
      21.9,
      6.5,
      70.5,
      49.1,
      354.8,
      8.7,
      21.2,
      5.7,
      61.8,
      31.3,
      225.7,
      12.7,
      26.9,
      8.4,
      92.2,
      40.5,
      292.1,
      17.0,
      29.0,
      9.6,
      104.9,
      46.1,
      332.5,
      15.9,
      31.8,
      11.8,
      129.1,
      46.1,
      333.0,
      17.3,
      33.7,
      15.6,
      170.7,
      37.5,
      271.0,
      17.2,
      35.4,
      7.8,
      85.5,
      60.5,
      436.8,
      10.2,
      26.9,
      7.3,
      79.8,
      46.0,
      332.4,
      14.9,
      34.1,
      10.9,
      118.9,
      59.6,
      430.3,
      19.9,
      36.7,
      12.4,
      135.3,
      67.8,
      489.9,
      18.6,
      40.4,
      15.2,
      166.6,
      67.9,
      490.5,
      20.2,
      42.7,
      20.1,
      220.2,
      55.3,
      399.2,
      20.1,
      44.9,
      10.1,
      110.3,
      89.1,
      643.5,
      10.1,
      32.6,
      11.0,
      120.3,
      51.9,
      374.8,
      14.7,
      41.4,
      16.4,
      179.2,
      67.2,
      485.1,
      19.7,
      44.5,
      18.7,
      204.0,
      76.5,
      552.3,
      18.4,
      49.0,
      23.0,
      251.2,
      76.6,
      553.1,
      20.0,
      51.8,
      30.4,
      331.9,
      62.3,
      450.1,
      19.9,
      54.4,
      15.2,
      166.3,
      100.5,
      725.5,
      10.7,
      35.8,
      8.6,
      93.7,
      64.9,
      468.4,
      15.6,
      45.4,
      12.8,
      139.6,
      84.0,
      606.2,
      20.8,
      48.8,
      14.5,
      158.9,
      95.6,
      690.2,
      19.5,
      53.7,
      17.9,
      195.6,
      95.7,
      691.1,
      21.2,
      56.8,
      23.7,
      258.6,
      77.9,
      562.4,
      21.0,
      59.7,
      11.8,
      129.5,
      125.6,
      906.6,
      12.4,
      40.7,
      12.3,
      133.9,
      69.6,
      502.5,
      18.1,
      51.7,
      18.3,
      199.5,
      90.1,
      650.4,
      24.1,
      55.6,
      20.8,
      227.1,
      102.6,
      740.5,
      22.6,
      61.2,
      25.6,
      279.6,
      102.7,
      741.5,
      24.5,
      64.7,
      33.8,
      369.5,
      83.6,
      603.4,
      24.3,
      68.0,
      16.9,
      185.1,
      134.7,
      972.6,
    ],
    [
      20,
      "Maine",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      21,
      "Maryland",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      22,
      "Massachusetts",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      23,
      "Michigan",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      9.0,
      81.1,
      50.1,
      547.5,
      67.3,
      485.9,
      5.6,
      38.6,
      26.5,
      289.6,
      33.3,
      240.4,
      8.4,
      62.3,
      32.1,
      351.0,
      41.0,
      296.0,
      9.9,
      79.0,
      41.4,
      452.8,
      60.4,
      436.1,
      9.8,
      95.8,
      62.5,
      682.7,
      68.1,
      491.7,
      10.3,
      105.1,
      48.7,
      531.7,
      85.1,
      614.4,
      12.0,
      119.7,
      69.5,
      759.9,
      91.3,
      659.2,
      3.8,
      29.9,
      17.3,
      189.3,
      25.4,
      183.3,
      5.6,
      38.0,
      25.8,
      282.1,
      32.9,
      237.2,
      7.4,
      40.9,
      29.4,
      321.1,
      37.4,
      270.1,
      6.9,
      44.9,
      36.2,
      395.4,
      37.5,
      270.4,
      7.5,
      47.5,
      47.8,
      522.5,
      30.5,
      220.1,
      7.5,
      49.9,
      23.9,
      261.7,
      49.1,
      354.8,
      5.8,
      48.3,
      21.0,
      229.5,
      31.3,
      225.7,
      8.4,
      61.3,
      31.3,
      342.0,
      40.5,
      292.1,
      11.2,
      66.0,
      35.6,
      389.2,
      46.1,
      332.5,
      10.5,
      72.5,
      43.8,
      479.2,
      46.1,
      333.0,
      11.4,
      76.7,
      58.0,
      633.4,
      37.5,
      271.0,
      11.3,
      80.6,
      29.0,
      317.2,
      60.5,
      436.8,
      6.7,
      61.2,
      27.1,
      296.0,
      46.0,
      332.4,
      9.8,
      77.8,
      40.4,
      441.2,
      59.6,
      430.3,
      13.1,
      83.6,
      45.9,
      502.1,
      67.8,
      489.9,
      12.3,
      92.0,
      56.6,
      618.2,
      67.9,
      490.5,
      13.4,
      97.3,
      74.8,
      817.0,
      55.3,
      399.2,
      13.3,
      102.2,
      37.4,
      409.2,
      89.1,
      643.5,
      6.7,
      74.3,
      40.8,
      446.4,
      51.9,
      374.8,
      9.7,
      94.3,
      60.9,
      665.2,
      67.2,
      485.1,
      13.0,
      101.5,
      69.3,
      757.1,
      76.5,
      552.3,
      12.2,
      111.6,
      85.3,
      932.1,
      76.6,
      553.1,
      13.2,
      118.0,
      112.7,
      1231.9,
      62.3,
      450.1,
      13.1,
      124.0,
      56.5,
      617.0,
      100.5,
      725.5,
      7.1,
      81.5,
      31.8,
      347.7,
      64.9,
      468.4,
      10.3,
      103.5,
      47.4,
      518.1,
      84.0,
      606.2,
      13.8,
      111.3,
      54.0,
      589.7,
      95.6,
      690.2,
      12.9,
      122.4,
      66.4,
      726.0,
      95.7,
      691.1,
      14.0,
      129.5,
      87.8,
      959.5,
      77.9,
      562.4,
      13.9,
      136.0,
      44.0,
      480.6,
      125.6,
      906.6,
      8.2,
      92.8,
      45.5,
      496.9,
      69.6,
      502.5,
      11.9,
      117.8,
      67.7,
      740.4,
      90.1,
      650.4,
      15.9,
      126.7,
      77.1,
      842.7,
      102.6,
      740.5,
      14.9,
      139.4,
      94.9,
      1037.5,
      102.7,
      741.5,
      16.2,
      147.4,
      125.5,
      1371.2,
      83.6,
      603.4,
      16.1,
      154.9,
      62.8,
      686.8,
      134.7,
      972.6,
    ],
    [
      24,
      "Minnesota",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      25,
      "Mississippi",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.4,
      47.0,
      19.2,
      209.8,
      67.3,
      485.9,
      8.9,
      22.4,
      10.2,
      111.0,
      33.3,
      240.4,
      13.4,
      36.1,
      12.3,
      134.5,
      41.0,
      296.0,
      15.7,
      45.8,
      15.9,
      173.5,
      60.4,
      436.1,
      15.6,
      55.5,
      23.9,
      261.6,
      68.1,
      491.7,
      16.5,
      60.9,
      18.6,
      203.8,
      85.1,
      614.4,
      19.1,
      69.4,
      26.6,
      291.2,
      91.3,
      659.2,
      6.1,
      17.3,
      6.6,
      72.6,
      25.4,
      183.3,
      8.9,
      22.0,
      9.9,
      108.1,
      32.9,
      237.2,
      11.8,
      23.7,
      11.3,
      123.1,
      37.4,
      270.1,
      11.1,
      26.0,
      13.9,
      151.5,
      37.5,
      270.4,
      12.0,
      27.5,
      18.3,
      200.2,
      30.5,
      220.1,
      11.9,
      28.9,
      9.2,
      100.3,
      49.1,
      354.8,
      9.2,
      28.0,
      8.0,
      87.9,
      31.3,
      225.7,
      13.4,
      35.5,
      12.0,
      131.1,
      40.5,
      292.1,
      17.9,
      38.2,
      13.6,
      149.2,
      46.1,
      332.5,
      16.7,
      42.0,
      16.8,
      183.7,
      46.1,
      333.0,
      18.2,
      44.5,
      22.2,
      242.7,
      37.5,
      271.0,
      18.1,
      46.7,
      11.1,
      121.6,
      60.5,
      436.8,
      10.7,
      35.5,
      10.4,
      113.5,
      46.0,
      332.4,
      15.7,
      45.1,
      15.5,
      169.1,
      59.6,
      430.3,
      21.0,
      48.5,
      17.6,
      192.4,
      67.8,
      489.9,
      19.6,
      53.3,
      21.7,
      236.9,
      67.9,
      490.5,
      21.3,
      56.4,
      28.6,
      313.1,
      55.3,
      399.2,
      21.1,
      59.2,
      14.4,
      156.8,
      89.1,
      643.5,
      10.6,
      43.0,
      15.7,
      171.1,
      51.9,
      374.8,
      15.5,
      54.7,
      23.3,
      254.9,
      67.2,
      485.1,
      20.8,
      58.8,
      26.5,
      290.1,
      76.5,
      552.3,
      19.4,
      64.7,
      32.7,
      357.2,
      76.6,
      553.1,
      21.1,
      68.4,
      43.2,
      472.1,
      62.3,
      450.1,
      20.9,
      71.9,
      21.6,
      236.5,
      100.5,
      725.5,
      11.2,
      47.2,
      12.2,
      133.2,
      64.9,
      468.4,
      16.4,
      60.0,
      18.2,
      198.6,
      84.0,
      606.2,
      22.0,
      64.5,
      20.7,
      226.0,
      95.6,
      690.2,
      20.5,
      70.9,
      25.5,
      278.2,
      95.7,
      691.1,
      22.3,
      75.0,
      33.6,
      367.7,
      77.9,
      562.4,
      22.1,
      78.8,
      16.9,
      184.2,
      125.6,
      906.6,
      13.0,
      53.8,
      17.4,
      190.4,
      69.6,
      502.5,
      19.0,
      68.3,
      26.0,
      283.7,
      90.1,
      650.4,
      25.4,
      73.4,
      29.5,
      322.9,
      102.6,
      740.5,
      23.7,
      80.8,
      36.4,
      397.6,
      102.7,
      741.5,
      25.8,
      85.4,
      48.1,
      525.5,
      83.6,
      603.4,
      25.6,
      89.8,
      24.1,
      263.2,
      134.7,
      972.6,
    ],
    [
      26,
      "Missouri",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      27,
      "Montana",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.3,
      65.2,
      44.6,
      487.4,
      67.3,
      485.9,
      5.1,
      31.0,
      23.6,
      257.8,
      33.3,
      240.4,
      7.8,
      50.1,
      28.6,
      312.5,
      41.0,
      296.0,
      9.1,
      63.5,
      36.9,
      403.1,
      60.4,
      436.1,
      9.0,
      77.0,
      55.6,
      607.7,
      68.1,
      491.7,
      9.5,
      84.5,
      43.3,
      473.4,
      85.1,
      614.4,
      11.0,
      96.2,
      61.9,
      676.5,
      91.3,
      659.2,
      3.5,
      24.0,
      15.4,
      168.5,
      25.4,
      183.3,
      5.1,
      30.5,
      23.0,
      251.2,
      32.9,
      237.2,
      6.8,
      32.8,
      26.2,
      285.9,
      37.4,
      270.1,
      6.4,
      36.1,
      32.2,
      352.0,
      37.5,
      270.4,
      6.9,
      38.2,
      42.6,
      465.2,
      30.5,
      220.1,
      6.9,
      40.1,
      21.3,
      233.0,
      49.1,
      354.8,
      5.3,
      38.8,
      18.7,
      204.3,
      31.3,
      225.7,
      7.7,
      49.3,
      27.9,
      304.4,
      40.5,
      292.1,
      10.3,
      53.0,
      31.7,
      346.5,
      46.1,
      332.5,
      9.7,
      58.3,
      39.0,
      426.6,
      46.1,
      333.0,
      10.5,
      61.7,
      51.6,
      563.8,
      37.5,
      271.0,
      10.4,
      64.8,
      25.8,
      282.4,
      60.5,
      436.8,
      6.2,
      49.2,
      24.1,
      263.5,
      46.0,
      332.4,
      9.0,
      62.5,
      35.9,
      392.7,
      59.6,
      430.3,
      12.1,
      67.2,
      40.9,
      447.0,
      67.8,
      489.9,
      11.3,
      73.9,
      50.4,
      550.3,
      67.9,
      490.5,
      12.3,
      78.2,
      66.6,
      727.3,
      55.3,
      399.2,
      12.2,
      82.2,
      33.3,
      364.3,
      89.1,
      643.5,
      6.1,
      59.7,
      36.4,
      397.4,
      51.9,
      374.8,
      8.9,
      75.8,
      54.2,
      592.1,
      67.2,
      485.1,
      12.0,
      81.6,
      61.7,
      674.0,
      76.5,
      552.3,
      11.2,
      89.7,
      75.9,
      829.8,
      76.6,
      553.1,
      12.2,
      94.9,
      100.3,
      1096.6,
      62.3,
      450.1,
      12.1,
      99.7,
      50.3,
      549.3,
      100.5,
      725.5,
      6.5,
      65.5,
      28.3,
      309.5,
      64.9,
      468.4,
      9.5,
      83.2,
      42.2,
      461.2,
      84.0,
      606.2,
      12.7,
      89.5,
      48.0,
      525.0,
      95.6,
      690.2,
      11.8,
      98.4,
      59.1,
      646.3,
      95.7,
      691.1,
      12.9,
      104.1,
      78.2,
      854.2,
      77.9,
      562.4,
      12.8,
      109.3,
      39.1,
      427.9,
      125.6,
      906.6,
      7.5,
      74.6,
      40.5,
      442.3,
      69.6,
      502.5,
      11.0,
      94.7,
      60.3,
      659.1,
      90.1,
      650.4,
      14.7,
      101.9,
      68.6,
      750.2,
      102.6,
      740.5,
      13.7,
      112.0,
      84.5,
      923.6,
      102.7,
      741.5,
      14.9,
      118.5,
      111.7,
      1220.7,
      83.6,
      603.4,
      14.8,
      124.5,
      55.9,
      611.4,
      134.7,
      972.6,
    ],
    [
      28,
      "Nebraska",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      29,
      "Nevada",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.0,
      32.4,
      24.0,
      262.3,
      59.2,
      427.4,
      4.9,
      15.4,
      12.7,
      138.7,
      29.3,
      211.5,
      7.5,
      24.9,
      15.4,
      168.1,
      36.1,
      260.4,
      8.7,
      31.6,
      19.8,
      216.9,
      53.1,
      383.6,
      8.7,
      38.3,
      29.9,
      327.0,
      59.9,
      432.5,
      9.2,
      42.0,
      23.3,
      254.7,
      74.9,
      540.5,
      10.6,
      47.8,
      33.3,
      364.0,
      80.3,
      579.9,
      3.4,
      11.9,
      8.3,
      90.7,
      22.3,
      161.2,
      4.9,
      15.2,
      12.4,
      135.2,
      28.9,
      208.7,
      6.6,
      16.3,
      14.1,
      153.8,
      32.9,
      237.6,
      6.2,
      17.9,
      17.3,
      189.4,
      32.9,
      237.9,
      6.7,
      19.0,
      22.9,
      250.3,
      26.8,
      193.6,
      6.6,
      19.9,
      11.5,
      125.4,
      43.2,
      312.1,
      5.1,
      19.3,
      10.1,
      109.9,
      27.5,
      198.5,
      7.5,
      24.5,
      15.0,
      163.8,
      35.6,
      256.9,
      10.0,
      26.4,
      17.1,
      186.5,
      40.5,
      292.5,
      9.3,
      29.0,
      21.0,
      229.6,
      40.6,
      292.9,
      10.1,
      30.7,
      27.8,
      303.4,
      33.0,
      238.3,
      10.1,
      32.2,
      13.9,
      152.0,
      53.2,
      384.2,
      6.0,
      24.5,
      13.0,
      141.8,
      40.5,
      292.4,
      8.7,
      31.1,
      19.3,
      211.3,
      52.4,
      378.5,
      11.7,
      33.4,
      22.0,
      240.5,
      59.7,
      430.9,
      10.9,
      36.7,
      27.1,
      296.1,
      59.8,
      431.5,
      11.9,
      38.9,
      35.8,
      391.4,
      48.6,
      351.1,
      11.8,
      40.8,
      17.9,
      196.0,
      78.4,
      566.0,
      5.9,
      29.7,
      19.6,
      213.8,
      45.7,
      329.7,
      8.6,
      37.7,
      29.2,
      318.6,
      59.1,
      426.7,
      11.5,
      40.5,
      33.2,
      362.7,
      67.3,
      485.9,
      10.8,
      44.6,
      40.9,
      446.5,
      67.4,
      486.5,
      11.7,
      47.2,
      54.0,
      590.1,
      54.8,
      395.9,
      11.6,
      49.5,
      27.0,
      295.6,
      88.4,
      638.2,
      6.3,
      32.5,
      15.2,
      166.6,
      57.1,
      412.0,
      9.1,
      41.3,
      22.7,
      248.2,
      73.9,
      533.3,
      12.2,
      44.5,
      25.8,
      282.5,
      84.1,
      607.1,
      11.4,
      48.9,
      31.8,
      347.8,
      84.2,
      607.9,
      12.4,
      51.7,
      42.1,
      459.7,
      68.5,
      494.7,
      12.3,
      54.3,
      21.1,
      230.2,
      110.5,
      797.5,
      7.2,
      37.1,
      21.8,
      238.0,
      61.2,
      442.0,
      10.6,
      47.1,
      32.5,
      354.7,
      79.2,
      572.1,
      14.1,
      50.6,
      36.9,
      403.7,
      90.2,
      651.4,
      13.2,
      55.7,
      45.5,
      497.0,
      90.3,
      652.2,
      14.4,
      58.9,
      60.1,
      656.9,
      73.5,
      530.8,
      14.3,
      61.9,
      30.1,
      329.0,
      118.5,
      855.6,
    ],
    [
      30,
      "New Hampshire",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      31,
      "New Jersey",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      32,
      "New Mexico",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.2,
      42.9,
      28.8,
      314.8,
      67.3,
      485.9,
      8.8,
      20.4,
      15.2,
      166.5,
      33.3,
      240.4,
      13.3,
      33.0,
      18.5,
      201.8,
      41.0,
      296.0,
      15.5,
      41.8,
      23.8,
      260.3,
      60.4,
      436.1,
      15.3,
      50.7,
      35.9,
      392.4,
      68.1,
      491.7,
      16.2,
      55.6,
      28.0,
      305.7,
      85.1,
      614.4,
      18.8,
      63.3,
      40.0,
      436.8,
      91.3,
      659.2,
      6.0,
      15.8,
      10.0,
      108.8,
      25.4,
      183.3,
      8.7,
      20.1,
      14.8,
      162.2,
      32.9,
      237.2,
      11.7,
      21.6,
      16.9,
      184.6,
      37.4,
      270.1,
      10.9,
      23.8,
      20.8,
      227.3,
      37.5,
      270.4,
      11.9,
      25.1,
      27.5,
      300.4,
      30.5,
      220.1,
      11.8,
      26.4,
      13.8,
      150.5,
      49.1,
      354.8,
      9.1,
      25.5,
      12.1,
      131.9,
      31.3,
      225.7,
      13.2,
      32.4,
      18.0,
      196.6,
      40.5,
      292.1,
      17.7,
      34.9,
      20.5,
      223.8,
      46.1,
      332.5,
      16.5,
      38.4,
      25.2,
      275.5,
      46.1,
      333.0,
      18.0,
      40.6,
      33.3,
      364.1,
      37.5,
      271.0,
      17.8,
      42.6,
      16.7,
      182.4,
      60.5,
      436.8,
      10.6,
      32.4,
      15.6,
      170.2,
      46.0,
      332.4,
      15.5,
      41.1,
      23.2,
      253.6,
      59.6,
      430.3,
      20.7,
      44.2,
      26.4,
      288.6,
      67.8,
      489.9,
      19.3,
      48.7,
      32.5,
      355.4,
      67.9,
      490.5,
      21.0,
      51.5,
      43.0,
      469.7,
      55.3,
      399.2,
      20.8,
      54.1,
      21.5,
      235.3,
      89.1,
      643.5,
      10.5,
      39.3,
      23.5,
      256.6,
      51.9,
      374.8,
      15.3,
      49.9,
      35.0,
      382.4,
      67.2,
      485.1,
      20.5,
      53.7,
      39.8,
      435.2,
      76.5,
      552.3,
      19.1,
      59.0,
      49.0,
      535.8,
      76.6,
      553.1,
      20.8,
      62.4,
      64.8,
      708.1,
      62.3,
      450.1,
      20.6,
      65.6,
      32.5,
      354.7,
      100.5,
      725.5,
      11.1,
      43.1,
      18.3,
      199.9,
      64.9,
      468.4,
      16.2,
      54.7,
      27.3,
      297.8,
      84.0,
      606.2,
      21.6,
      58.9,
      31.0,
      339.0,
      95.6,
      690.2,
      20.2,
      64.7,
      38.2,
      417.4,
      95.7,
      691.1,
      22.0,
      68.5,
      50.5,
      551.6,
      77.9,
      562.4,
      21.8,
      71.9,
      25.3,
      276.3,
      125.6,
      906.6,
      12.8,
      49.1,
      26.1,
      285.6,
      69.6,
      502.5,
      18.7,
      62.3,
      38.9,
      425.6,
      90.1,
      650.4,
      25.1,
      67.0,
      44.3,
      484.4,
      102.6,
      740.5,
      23.4,
      73.7,
      54.6,
      596.4,
      102.7,
      741.5,
      25.5,
      78.0,
      72.1,
      788.2,
      83.6,
      603.4,
      25.3,
      81.9,
      36.1,
      394.8,
      134.7,
      972.6,
    ],
    [
      33,
      "New York",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      34,
      "North Carolina",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.4,
      69.6,
      33.9,
      370.5,
      62.4,
      450.5,
      5.2,
      33.1,
      17.9,
      195.9,
      30.9,
      222.9,
      7.8,
      53.5,
      21.7,
      237.5,
      38.0,
      274.5,
      9.2,
      67.8,
      28.0,
      306.4,
      56.0,
      404.3,
      9.1,
      82.2,
      42.3,
      461.9,
      63.1,
      455.9,
      9.6,
      90.2,
      32.9,
      359.8,
      78.9,
      569.7,
      11.1,
      102.7,
      47.0,
      514.2,
      84.7,
      611.2,
      3.5,
      25.7,
      11.7,
      128.1,
      23.5,
      169.9,
      5.2,
      32.6,
      17.5,
      190.9,
      30.5,
      219.9,
      6.9,
      35.1,
      19.9,
      217.3,
      34.7,
      250.4,
      6.4,
      38.6,
      24.5,
      267.5,
      34.7,
      250.8,
      7.0,
      40.8,
      32.4,
      353.6,
      28.3,
      204.0,
      7.0,
      42.9,
      16.2,
      177.1,
      45.6,
      328.9,
      5.3,
      41.4,
      14.2,
      155.3,
      29.0,
      209.2,
      7.8,
      52.6,
      21.2,
      231.4,
      37.5,
      270.8,
      10.4,
      56.6,
      24.1,
      263.4,
      42.7,
      308.3,
      9.8,
      62.3,
      29.7,
      324.3,
      42.8,
      308.7,
      10.6,
      65.9,
      39.2,
      428.6,
      34.8,
      251.2,
      10.5,
      69.2,
      19.6,
      214.7,
      56.1,
      405.0,
      6.3,
      52.5,
      18.3,
      200.3,
      42.7,
      308.2,
      9.1,
      66.7,
      27.3,
      298.5,
      55.3,
      398.9,
      12.2,
      71.8,
      31.1,
      339.8,
      62.9,
      454.2,
      11.4,
      78.9,
      38.3,
      418.3,
      63.0,
      454.8,
      12.4,
      83.5,
      50.6,
      552.8,
      51.3,
      370.1,
      12.3,
      87.7,
      25.3,
      276.9,
      82.6,
      596.6,
      6.2,
      63.7,
      27.6,
      302.0,
      48.1,
      347.5,
      9.0,
      81.0,
      41.2,
      450.1,
      62.3,
      449.8,
      12.1,
      87.1,
      46.9,
      512.3,
      70.9,
      512.1,
      11.3,
      95.8,
      57.7,
      630.7,
      71.0,
      512.8,
      12.3,
      101.3,
      76.3,
      833.5,
      57.8,
      417.3,
      12.2,
      106.4,
      38.2,
      417.5,
      93.2,
      672.7,
      6.6,
      69.9,
      21.5,
      235.3,
      60.1,
      434.3,
      9.6,
      88.8,
      32.1,
      350.6,
      77.8,
      562.1,
      12.8,
      95.5,
      36.5,
      399.0,
      88.6,
      640.0,
      12.0,
      105.0,
      45.0,
      491.3,
      88.8,
      640.8,
      13.0,
      111.1,
      59.4,
      649.3,
      72.2,
      521.5,
      12.9,
      116.7,
      29.8,
      325.2,
      116.4,
      840.6,
      7.6,
      79.6,
      30.8,
      336.2,
      64.5,
      465.9,
      11.1,
      101.1,
      45.8,
      501.0,
      83.5,
      603.0,
      14.8,
      108.8,
      52.2,
      570.2,
      95.1,
      686.6,
      13.8,
      119.6,
      64.2,
      702.0,
      95.2,
      687.5,
      15.1,
      126.5,
      84.9,
      927.8,
      77.5,
      559.4,
      14.9,
      132.9,
      42.5,
      464.7,
      124.9,
      901.8,
    ],
    [
      35,
      "North Dakota",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      36,
      "Ohio",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      9.0,
      81.1,
      50.1,
      547.5,
      67.3,
      485.9,
      5.6,
      38.6,
      26.5,
      289.6,
      33.3,
      240.4,
      8.4,
      62.3,
      32.1,
      351.0,
      41.0,
      296.0,
      9.9,
      79.0,
      41.4,
      452.8,
      60.4,
      436.1,
      9.8,
      95.8,
      62.5,
      682.7,
      68.1,
      491.7,
      10.3,
      105.1,
      48.7,
      531.7,
      85.1,
      614.4,
      12.0,
      119.7,
      69.5,
      759.9,
      91.3,
      659.2,
      3.8,
      29.9,
      17.3,
      189.3,
      25.4,
      183.3,
      5.6,
      38.0,
      25.8,
      282.1,
      32.9,
      237.2,
      7.4,
      40.9,
      29.4,
      321.1,
      37.4,
      270.1,
      6.9,
      44.9,
      36.2,
      395.4,
      37.5,
      270.4,
      7.5,
      47.5,
      47.8,
      522.5,
      30.5,
      220.1,
      7.5,
      49.9,
      23.9,
      261.7,
      49.1,
      354.8,
      5.8,
      48.3,
      21.0,
      229.5,
      31.3,
      225.7,
      8.4,
      61.3,
      31.3,
      342.0,
      40.5,
      292.1,
      11.2,
      66.0,
      35.6,
      389.2,
      46.1,
      332.5,
      10.5,
      72.5,
      43.8,
      479.2,
      46.1,
      333.0,
      11.4,
      76.7,
      58.0,
      633.4,
      37.5,
      271.0,
      11.3,
      80.6,
      29.0,
      317.2,
      60.5,
      436.8,
      6.7,
      61.2,
      27.1,
      296.0,
      46.0,
      332.4,
      9.8,
      77.8,
      40.4,
      441.2,
      59.6,
      430.3,
      13.1,
      83.6,
      45.9,
      502.1,
      67.8,
      489.9,
      12.3,
      92.0,
      56.6,
      618.2,
      67.9,
      490.5,
      13.4,
      97.3,
      74.8,
      817.0,
      55.3,
      399.2,
      13.3,
      102.2,
      37.4,
      409.2,
      89.1,
      643.5,
      6.7,
      74.3,
      40.8,
      446.4,
      51.9,
      374.8,
      9.7,
      94.3,
      60.9,
      665.2,
      67.2,
      485.1,
      13.0,
      101.5,
      69.3,
      757.1,
      76.5,
      552.3,
      12.2,
      111.6,
      85.3,
      932.1,
      76.6,
      553.1,
      13.2,
      118.0,
      112.7,
      1231.9,
      62.3,
      450.1,
      13.1,
      124.0,
      56.5,
      617.0,
      100.5,
      725.5,
      7.1,
      81.5,
      31.8,
      347.7,
      64.9,
      468.4,
      10.3,
      103.5,
      47.4,
      518.1,
      84.0,
      606.2,
      13.8,
      111.3,
      54.0,
      589.7,
      95.6,
      690.2,
      12.9,
      122.4,
      66.4,
      726.0,
      95.7,
      691.1,
      14.0,
      129.5,
      87.8,
      959.5,
      77.9,
      562.4,
      13.9,
      136.0,
      44.0,
      480.6,
      125.6,
      906.6,
      8.2,
      92.8,
      45.5,
      496.9,
      69.6,
      502.5,
      11.9,
      117.8,
      67.7,
      740.4,
      90.1,
      650.4,
      15.9,
      126.7,
      77.1,
      842.7,
      102.6,
      740.5,
      14.9,
      139.4,
      94.9,
      1037.5,
      102.7,
      741.5,
      16.2,
      147.4,
      125.5,
      1371.2,
      83.6,
      603.4,
      16.1,
      154.9,
      62.8,
      686.8,
      134.7,
      972.6,
    ],
    [
      37,
      "Oklahoma",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.2,
      42.9,
      28.8,
      314.8,
      67.3,
      485.9,
      8.8,
      20.4,
      15.2,
      166.5,
      33.3,
      240.4,
      13.3,
      33.0,
      18.5,
      201.8,
      41.0,
      296.0,
      15.5,
      41.8,
      23.8,
      260.3,
      60.4,
      436.1,
      15.3,
      50.7,
      35.9,
      392.4,
      68.1,
      491.7,
      16.2,
      55.6,
      28.0,
      305.7,
      85.1,
      614.4,
      18.8,
      63.3,
      40.0,
      436.8,
      91.3,
      659.2,
      6.0,
      15.8,
      10.0,
      108.8,
      25.4,
      183.3,
      8.7,
      20.1,
      14.8,
      162.2,
      32.9,
      237.2,
      11.7,
      21.6,
      16.9,
      184.6,
      37.4,
      270.1,
      10.9,
      23.8,
      20.8,
      227.3,
      37.5,
      270.4,
      11.9,
      25.1,
      27.5,
      300.4,
      30.5,
      220.1,
      11.8,
      26.4,
      13.8,
      150.5,
      49.1,
      354.8,
      9.1,
      25.5,
      12.1,
      131.9,
      31.3,
      225.7,
      13.2,
      32.4,
      18.0,
      196.6,
      40.5,
      292.1,
      17.7,
      34.9,
      20.5,
      223.8,
      46.1,
      332.5,
      16.5,
      38.4,
      25.2,
      275.5,
      46.1,
      333.0,
      18.0,
      40.6,
      33.3,
      364.1,
      37.5,
      271.0,
      17.8,
      42.6,
      16.7,
      182.4,
      60.5,
      436.8,
      10.6,
      32.4,
      15.6,
      170.2,
      46.0,
      332.4,
      15.5,
      41.1,
      23.2,
      253.6,
      59.6,
      430.3,
      20.7,
      44.2,
      26.4,
      288.6,
      67.8,
      489.9,
      19.3,
      48.7,
      32.5,
      355.4,
      67.9,
      490.5,
      21.0,
      51.5,
      43.0,
      469.7,
      55.3,
      399.2,
      20.8,
      54.1,
      21.5,
      235.3,
      89.1,
      643.5,
      10.5,
      39.3,
      23.5,
      256.6,
      51.9,
      374.8,
      15.3,
      49.9,
      35.0,
      382.4,
      67.2,
      485.1,
      20.5,
      53.7,
      39.8,
      435.2,
      76.5,
      552.3,
      19.1,
      59.0,
      49.0,
      535.8,
      76.6,
      553.1,
      20.8,
      62.4,
      64.8,
      708.1,
      62.3,
      450.1,
      20.6,
      65.6,
      32.5,
      354.7,
      100.5,
      725.5,
      11.1,
      43.1,
      18.3,
      199.9,
      64.9,
      468.4,
      16.2,
      54.7,
      27.3,
      297.8,
      84.0,
      606.2,
      21.6,
      58.9,
      31.0,
      339.0,
      95.6,
      690.2,
      20.2,
      64.7,
      38.2,
      417.4,
      95.7,
      691.1,
      22.0,
      68.5,
      50.5,
      551.6,
      77.9,
      562.4,
      21.8,
      71.9,
      25.3,
      276.3,
      125.6,
      906.6,
      12.8,
      49.1,
      26.1,
      285.6,
      69.6,
      502.5,
      18.7,
      62.3,
      38.9,
      425.6,
      90.1,
      650.4,
      25.1,
      67.0,
      44.3,
      484.4,
      102.6,
      740.5,
      23.4,
      73.7,
      54.6,
      596.4,
      102.7,
      741.5,
      25.5,
      78.0,
      72.1,
      788.2,
      83.6,
      603.4,
      25.3,
      81.9,
      36.1,
      394.8,
      134.7,
      972.6,
    ],
    [
      38,
      "Oregon",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.0,
      32.4,
      24.0,
      262.3,
      59.2,
      427.4,
      4.9,
      15.4,
      12.7,
      138.7,
      29.3,
      211.5,
      7.5,
      24.9,
      15.4,
      168.1,
      36.1,
      260.4,
      8.7,
      31.6,
      19.8,
      216.9,
      53.1,
      383.6,
      8.7,
      38.3,
      29.9,
      327.0,
      59.9,
      432.5,
      9.2,
      42.0,
      23.3,
      254.7,
      74.9,
      540.5,
      10.6,
      47.8,
      33.3,
      364.0,
      80.3,
      579.9,
      3.4,
      11.9,
      8.3,
      90.7,
      22.3,
      161.2,
      4.9,
      15.2,
      12.4,
      135.2,
      28.9,
      208.7,
      6.6,
      16.3,
      14.1,
      153.8,
      32.9,
      237.6,
      6.2,
      17.9,
      17.3,
      189.4,
      32.9,
      237.9,
      6.7,
      19.0,
      22.9,
      250.3,
      26.8,
      193.6,
      6.6,
      19.9,
      11.5,
      125.4,
      43.2,
      312.1,
      5.1,
      19.3,
      10.1,
      109.9,
      27.5,
      198.5,
      7.5,
      24.5,
      15.0,
      163.8,
      35.6,
      256.9,
      10.0,
      26.4,
      17.1,
      186.5,
      40.5,
      292.5,
      9.3,
      29.0,
      21.0,
      229.6,
      40.6,
      292.9,
      10.1,
      30.7,
      27.8,
      303.4,
      33.0,
      238.3,
      10.1,
      32.2,
      13.9,
      152.0,
      53.2,
      384.2,
      6.0,
      24.5,
      13.0,
      141.8,
      40.5,
      292.4,
      8.7,
      31.1,
      19.3,
      211.3,
      52.4,
      378.5,
      11.7,
      33.4,
      22.0,
      240.5,
      59.7,
      430.9,
      10.9,
      36.7,
      27.1,
      296.1,
      59.8,
      431.5,
      11.9,
      38.9,
      35.8,
      391.4,
      48.6,
      351.1,
      11.8,
      40.8,
      17.9,
      196.0,
      78.4,
      566.0,
      5.9,
      29.7,
      19.6,
      213.8,
      45.7,
      329.7,
      8.6,
      37.7,
      29.2,
      318.6,
      59.1,
      426.7,
      11.5,
      40.5,
      33.2,
      362.7,
      67.3,
      485.9,
      10.8,
      44.6,
      40.9,
      446.5,
      67.4,
      486.5,
      11.7,
      47.2,
      54.0,
      590.1,
      54.8,
      395.9,
      11.6,
      49.5,
      27.0,
      295.6,
      88.4,
      638.2,
      6.3,
      32.5,
      15.2,
      166.6,
      57.1,
      412.0,
      9.1,
      41.3,
      22.7,
      248.2,
      73.9,
      533.3,
      12.2,
      44.5,
      25.8,
      282.5,
      84.1,
      607.1,
      11.4,
      48.9,
      31.8,
      347.8,
      84.2,
      607.9,
      12.4,
      51.7,
      42.1,
      459.7,
      68.5,
      494.7,
      12.3,
      54.3,
      21.1,
      230.2,
      110.5,
      797.5,
      7.2,
      37.1,
      21.8,
      238.0,
      61.2,
      442.0,
      10.6,
      47.1,
      32.5,
      354.7,
      79.2,
      572.1,
      14.1,
      50.6,
      36.9,
      403.7,
      90.2,
      651.4,
      13.2,
      55.7,
      45.5,
      497.0,
      90.3,
      652.2,
      14.4,
      58.9,
      60.1,
      656.9,
      73.5,
      530.8,
      14.3,
      61.9,
      30.1,
      329.0,
      118.5,
      855.6,
    ],
    [
      39,
      "Pennsylvania",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      40,
      "Rhode Island",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      41,
      "South Carolina",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.4,
      69.6,
      33.9,
      370.5,
      62.4,
      450.5,
      5.2,
      33.1,
      17.9,
      195.9,
      30.9,
      222.9,
      7.8,
      53.5,
      21.7,
      237.5,
      38.0,
      274.5,
      9.2,
      67.8,
      28.0,
      306.4,
      56.0,
      404.3,
      9.1,
      82.2,
      42.3,
      461.9,
      63.1,
      455.9,
      9.6,
      90.2,
      32.9,
      359.8,
      78.9,
      569.7,
      11.1,
      102.7,
      47.0,
      514.2,
      84.7,
      611.2,
      3.5,
      25.7,
      11.7,
      128.1,
      23.5,
      169.9,
      5.2,
      32.6,
      17.5,
      190.9,
      30.5,
      219.9,
      6.9,
      35.1,
      19.9,
      217.3,
      34.7,
      250.4,
      6.4,
      38.6,
      24.5,
      267.5,
      34.7,
      250.8,
      7.0,
      40.8,
      32.4,
      353.6,
      28.3,
      204.0,
      7.0,
      42.9,
      16.2,
      177.1,
      45.6,
      328.9,
      5.3,
      41.4,
      14.2,
      155.3,
      29.0,
      209.2,
      7.8,
      52.6,
      21.2,
      231.4,
      37.5,
      270.8,
      10.4,
      56.6,
      24.1,
      263.4,
      42.7,
      308.3,
      9.8,
      62.3,
      29.7,
      324.3,
      42.8,
      308.7,
      10.6,
      65.9,
      39.2,
      428.6,
      34.8,
      251.2,
      10.5,
      69.2,
      19.6,
      214.7,
      56.1,
      405.0,
      6.3,
      52.5,
      18.3,
      200.3,
      42.7,
      308.2,
      9.1,
      66.7,
      27.3,
      298.5,
      55.3,
      398.9,
      12.2,
      71.8,
      31.1,
      339.8,
      62.9,
      454.2,
      11.4,
      78.9,
      38.3,
      418.3,
      63.0,
      454.8,
      12.4,
      83.5,
      50.6,
      552.8,
      51.3,
      370.1,
      12.3,
      87.7,
      25.3,
      276.9,
      82.6,
      596.6,
      6.2,
      63.7,
      27.6,
      302.0,
      48.1,
      347.5,
      9.0,
      81.0,
      41.2,
      450.1,
      62.3,
      449.8,
      12.1,
      87.1,
      46.9,
      512.3,
      70.9,
      512.1,
      11.3,
      95.8,
      57.7,
      630.7,
      71.0,
      512.8,
      12.3,
      101.3,
      76.3,
      833.5,
      57.8,
      417.3,
      12.2,
      106.4,
      38.2,
      417.5,
      93.2,
      672.7,
      6.6,
      69.9,
      21.5,
      235.3,
      60.1,
      434.3,
      9.6,
      88.8,
      32.1,
      350.6,
      77.8,
      562.1,
      12.8,
      95.5,
      36.5,
      399.0,
      88.6,
      640.0,
      12.0,
      105.0,
      45.0,
      491.3,
      88.8,
      640.8,
      13.0,
      111.1,
      59.4,
      649.3,
      72.2,
      521.5,
      12.9,
      116.7,
      29.8,
      325.2,
      116.4,
      840.6,
      7.6,
      79.6,
      30.8,
      336.2,
      64.5,
      465.9,
      11.1,
      101.1,
      45.8,
      501.0,
      83.5,
      603.0,
      14.8,
      108.8,
      52.2,
      570.2,
      95.1,
      686.6,
      13.8,
      119.6,
      64.2,
      702.0,
      95.2,
      687.5,
      15.1,
      126.5,
      84.9,
      927.8,
      77.5,
      559.4,
      14.9,
      132.9,
      42.5,
      464.7,
      124.9,
      901.8,
    ],
    [
      42,
      "South Dakota",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      43,
      "Tennessee",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      14.4,
      47.0,
      19.2,
      209.8,
      67.3,
      485.9,
      8.9,
      22.4,
      10.2,
      111.0,
      33.3,
      240.4,
      13.4,
      36.1,
      12.3,
      134.5,
      41.0,
      296.0,
      15.7,
      45.8,
      15.9,
      173.5,
      60.4,
      436.1,
      15.6,
      55.5,
      23.9,
      261.6,
      68.1,
      491.7,
      16.5,
      60.9,
      18.6,
      203.8,
      85.1,
      614.4,
      19.1,
      69.4,
      26.6,
      291.2,
      91.3,
      659.2,
      6.1,
      17.3,
      6.6,
      72.6,
      25.4,
      183.3,
      8.9,
      22.0,
      9.9,
      108.1,
      32.9,
      237.2,
      11.8,
      23.7,
      11.3,
      123.1,
      37.4,
      270.1,
      11.1,
      26.0,
      13.9,
      151.5,
      37.5,
      270.4,
      12.0,
      27.5,
      18.3,
      200.2,
      30.5,
      220.1,
      11.9,
      28.9,
      9.2,
      100.3,
      49.1,
      354.8,
      9.2,
      28.0,
      8.0,
      87.9,
      31.3,
      225.7,
      13.4,
      35.5,
      12.0,
      131.1,
      40.5,
      292.1,
      17.9,
      38.2,
      13.6,
      149.2,
      46.1,
      332.5,
      16.7,
      42.0,
      16.8,
      183.7,
      46.1,
      333.0,
      18.2,
      44.5,
      22.2,
      242.7,
      37.5,
      271.0,
      18.1,
      46.7,
      11.1,
      121.6,
      60.5,
      436.8,
      10.7,
      35.5,
      10.4,
      113.5,
      46.0,
      332.4,
      15.7,
      45.1,
      15.5,
      169.1,
      59.6,
      430.3,
      21.0,
      48.5,
      17.6,
      192.4,
      67.8,
      489.9,
      19.6,
      53.3,
      21.7,
      236.9,
      67.9,
      490.5,
      21.3,
      56.4,
      28.6,
      313.1,
      55.3,
      399.2,
      21.1,
      59.2,
      14.4,
      156.8,
      89.1,
      643.5,
      10.6,
      43.0,
      15.7,
      171.1,
      51.9,
      374.8,
      15.5,
      54.7,
      23.3,
      254.9,
      67.2,
      485.1,
      20.8,
      58.8,
      26.5,
      290.1,
      76.5,
      552.3,
      19.4,
      64.7,
      32.7,
      357.2,
      76.6,
      553.1,
      21.1,
      68.4,
      43.2,
      472.1,
      62.3,
      450.1,
      20.9,
      71.9,
      21.6,
      236.5,
      100.5,
      725.5,
      11.2,
      47.2,
      12.2,
      133.2,
      64.9,
      468.4,
      16.4,
      60.0,
      18.2,
      198.6,
      84.0,
      606.2,
      22.0,
      64.5,
      20.7,
      226.0,
      95.6,
      690.2,
      20.5,
      70.9,
      25.5,
      278.2,
      95.7,
      691.1,
      22.3,
      75.0,
      33.6,
      367.7,
      77.9,
      562.4,
      22.1,
      78.8,
      16.9,
      184.2,
      125.6,
      906.6,
      13.0,
      53.8,
      17.4,
      190.4,
      69.6,
      502.5,
      19.0,
      68.3,
      26.0,
      283.7,
      90.1,
      650.4,
      25.4,
      73.4,
      29.5,
      322.9,
      102.6,
      740.5,
      23.7,
      80.8,
      36.4,
      397.6,
      102.7,
      741.5,
      25.8,
      85.4,
      48.1,
      525.5,
      83.6,
      603.4,
      25.6,
      89.8,
      24.1,
      263.2,
      134.7,
      972.6,
    ],
    [
      44,
      "Texas",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      13.7,
      35.6,
      13.5,
      147.5,
      67.3,
      485.9,
      8.4,
      16.9,
      7.1,
      78.0,
      33.3,
      240.4,
      12.8,
      27.3,
      8.7,
      94.6,
      41.0,
      296.0,
      14.9,
      34.7,
      11.2,
      122.0,
      60.4,
      436.1,
      14.8,
      42.1,
      16.8,
      184.0,
      68.1,
      491.7,
      15.6,
      46.1,
      13.1,
      143.3,
      85.1,
      614.4,
      18.1,
      52.5,
      18.7,
      204.8,
      91.3,
      659.2,
      5.8,
      13.1,
      4.7,
      51.0,
      25.4,
      183.3,
      8.4,
      16.7,
      7.0,
      76.0,
      32.9,
      237.2,
      11.2,
      17.9,
      7.9,
      86.5,
      37.4,
      270.1,
      10.5,
      19.7,
      9.7,
      106.5,
      37.5,
      270.4,
      11.4,
      20.9,
      12.9,
      140.8,
      30.5,
      220.1,
      11.3,
      21.9,
      6.5,
      70.5,
      49.1,
      354.8,
      8.7,
      21.2,
      5.7,
      61.8,
      31.3,
      225.7,
      12.7,
      26.9,
      8.4,
      92.2,
      40.5,
      292.1,
      17.0,
      29.0,
      9.6,
      104.9,
      46.1,
      332.5,
      15.9,
      31.8,
      11.8,
      129.1,
      46.1,
      333.0,
      17.3,
      33.7,
      15.6,
      170.7,
      37.5,
      271.0,
      17.2,
      35.4,
      7.8,
      85.5,
      60.5,
      436.8,
      10.2,
      26.9,
      7.3,
      79.8,
      46.0,
      332.4,
      14.9,
      34.1,
      10.9,
      118.9,
      59.6,
      430.3,
      19.9,
      36.7,
      12.4,
      135.3,
      67.8,
      489.9,
      18.6,
      40.4,
      15.2,
      166.6,
      67.9,
      490.5,
      20.2,
      42.7,
      20.1,
      220.2,
      55.3,
      399.2,
      20.1,
      44.9,
      10.1,
      110.3,
      89.1,
      643.5,
      10.1,
      32.6,
      11.0,
      120.3,
      51.9,
      374.8,
      14.7,
      41.4,
      16.4,
      179.2,
      67.2,
      485.1,
      19.7,
      44.5,
      18.7,
      204.0,
      76.5,
      552.3,
      18.4,
      49.0,
      23.0,
      251.2,
      76.6,
      553.1,
      20.0,
      51.8,
      30.4,
      331.9,
      62.3,
      450.1,
      19.9,
      54.4,
      15.2,
      166.3,
      100.5,
      725.5,
      10.7,
      35.8,
      8.6,
      93.7,
      64.9,
      468.4,
      15.6,
      45.4,
      12.8,
      139.6,
      84.0,
      606.2,
      20.8,
      48.8,
      14.5,
      158.9,
      95.6,
      690.2,
      19.5,
      53.7,
      17.9,
      195.6,
      95.7,
      691.1,
      21.2,
      56.8,
      23.7,
      258.6,
      77.9,
      562.4,
      21.0,
      59.7,
      11.8,
      129.5,
      125.6,
      906.6,
      12.4,
      40.7,
      12.3,
      133.9,
      69.6,
      502.5,
      18.1,
      51.7,
      18.3,
      199.5,
      90.1,
      650.4,
      24.1,
      55.6,
      20.8,
      227.1,
      102.6,
      740.5,
      22.6,
      61.2,
      25.6,
      279.6,
      102.7,
      741.5,
      24.5,
      64.7,
      33.8,
      369.5,
      83.6,
      603.4,
      24.3,
      68.0,
      16.9,
      185.1,
      134.7,
      972.6,
    ],
    [
      45,
      "Utah",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.3,
      43.0,
      25.4,
      277.6,
      67.3,
      485.9,
      6.4,
      20.5,
      13.4,
      146.8,
      33.3,
      240.4,
      9.6,
      33.0,
      16.3,
      177.9,
      41.0,
      296.0,
      11.3,
      41.9,
      21.0,
      229.6,
      60.4,
      436.1,
      11.2,
      50.8,
      31.7,
      346.1,
      68.1,
      491.7,
      11.8,
      55.7,
      24.7,
      269.6,
      85.1,
      614.4,
      13.7,
      63.5,
      35.3,
      385.3,
      91.3,
      659.2,
      4.4,
      15.9,
      8.8,
      96.0,
      25.4,
      183.3,
      6.4,
      20.1,
      13.1,
      143.0,
      32.9,
      237.2,
      8.5,
      21.7,
      14.9,
      162.8,
      37.4,
      270.1,
      7.9,
      23.8,
      18.3,
      200.4,
      37.5,
      270.4,
      8.6,
      25.2,
      24.2,
      264.9,
      30.5,
      220.1,
      8.6,
      26.5,
      12.1,
      132.7,
      49.1,
      354.8,
      6.6,
      25.6,
      10.6,
      116.3,
      31.3,
      225.7,
      9.6,
      32.5,
      15.9,
      173.4,
      40.5,
      292.1,
      12.9,
      35.0,
      18.1,
      197.3,
      46.1,
      332.5,
      12.0,
      38.5,
      22.2,
      243.0,
      46.1,
      333.0,
      13.1,
      40.7,
      29.4,
      321.1,
      37.5,
      271.0,
      13.0,
      42.7,
      14.7,
      160.8,
      60.5,
      436.8,
      7.7,
      32.5,
      13.7,
      150.1,
      46.0,
      332.4,
      11.3,
      41.2,
      20.5,
      223.7,
      59.6,
      430.3,
      15.0,
      44.3,
      23.3,
      254.6,
      67.8,
      489.9,
      14.1,
      48.8,
      28.7,
      313.4,
      67.9,
      490.5,
      15.3,
      51.6,
      37.9,
      414.2,
      55.3,
      399.2,
      15.2,
      54.2,
      19.0,
      207.5,
      89.1,
      643.5,
      7.6,
      39.4,
      20.7,
      226.3,
      51.9,
      374.8,
      11.1,
      50.0,
      30.9,
      337.2,
      67.2,
      485.1,
      14.9,
      53.8,
      35.1,
      383.8,
      76.5,
      552.3,
      13.9,
      59.2,
      43.2,
      472.6,
      76.6,
      553.1,
      15.1,
      62.6,
      57.1,
      624.5,
      62.3,
      450.1,
      15.0,
      65.8,
      28.6,
      312.8,
      100.5,
      725.5,
      8.1,
      43.2,
      16.1,
      176.3,
      64.9,
      468.4,
      11.8,
      54.9,
      24.0,
      262.7,
      84.0,
      606.2,
      15.8,
      59.0,
      27.4,
      299.0,
      95.6,
      690.2,
      14.7,
      64.9,
      33.7,
      368.1,
      95.7,
      691.1,
      16.0,
      68.6,
      44.5,
      486.5,
      77.9,
      562.4,
      15.9,
      72.1,
      22.3,
      243.7,
      125.6,
      906.6,
      9.3,
      49.2,
      23.0,
      251.9,
      69.6,
      502.5,
      13.6,
      62.5,
      34.3,
      375.4,
      90.1,
      650.4,
      18.2,
      67.2,
      39.1,
      427.2,
      102.6,
      740.5,
      17.0,
      73.9,
      48.1,
      526.0,
      102.7,
      741.5,
      18.5,
      78.2,
      63.6,
      695.2,
      83.6,
      603.4,
      18.4,
      82.1,
      31.9,
      348.2,
      134.7,
      972.6,
    ],
    [
      46,
      "Vermont",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      7.4,
      64.8,
      24.7,
      269.9,
      86.1,
      621.7,
      4.6,
      30.8,
      13.1,
      142.8,
      42.6,
      307.6,
      6.9,
      49.8,
      15.8,
      173.0,
      52.5,
      378.7,
      8.1,
      63.1,
      20.4,
      223.2,
      77.3,
      557.9,
      8.0,
      76.6,
      30.8,
      336.6,
      87.1,
      629.1,
      8.5,
      84.0,
      24.0,
      262.2,
      108.9,
      786.1,
      9.8,
      95.6,
      34.3,
      374.6,
      116.8,
      843.4,
      3.1,
      23.9,
      8.5,
      93.3,
      32.5,
      234.5,
      4.6,
      30.4,
      12.7,
      139.1,
      42.0,
      303.5,
      6.1,
      32.6,
      14.5,
      158.3,
      47.9,
      345.5,
      5.7,
      35.9,
      17.8,
      194.9,
      47.9,
      346.0,
      6.2,
      38.0,
      23.6,
      257.6,
      39.0,
      281.5,
      6.2,
      39.9,
      11.8,
      129.0,
      62.9,
      453.9,
      4.7,
      38.6,
      10.4,
      113.1,
      40.0,
      288.7,
      6.9,
      49.0,
      15.4,
      168.6,
      51.8,
      373.7,
      9.2,
      52.7,
      17.6,
      191.9,
      58.9,
      425.4,
      8.6,
      58.0,
      21.6,
      236.3,
      59.0,
      426.0,
      9.4,
      61.3,
      28.6,
      312.3,
      48.0,
      346.6,
      9.3,
      64.4,
      14.3,
      156.4,
      77.4,
      558.8,
      5.5,
      48.9,
      13.4,
      146.0,
      58.9,
      425.3,
      8.1,
      62.1,
      19.9,
      217.5,
      76.2,
      550.5,
      10.8,
      66.8,
      22.7,
      247.5,
      86.8,
      626.7,
      10.1,
      73.5,
      27.9,
      304.8,
      86.9,
      627.6,
      11.0,
      77.8,
      36.9,
      402.8,
      70.7,
      510.7,
      10.9,
      81.7,
      18.5,
      201.8,
      114.0,
      823.2,
      5.5,
      59.3,
      20.1,
      220.1,
      66.4,
      479.5,
      8.0,
      75.4,
      30.0,
      327.9,
      86.0,
      620.6,
      10.7,
      81.1,
      34.2,
      373.2,
      97.9,
      706.6,
      10.0,
      89.2,
      42.0,
      459.5,
      98.0,
      707.6,
      10.9,
      94.3,
      55.6,
      607.3,
      79.7,
      575.8,
      10.8,
      99.1,
      27.8,
      304.2,
      128.5,
      928.2,
      5.8,
      65.1,
      15.7,
      171.4,
      83.0,
      599.2,
      8.5,
      82.7,
      23.4,
      255.4,
      107.4,
      775.6,
      11.3,
      88.9,
      26.6,
      290.7,
      122.3,
      883.0,
      10.6,
      97.8,
      32.8,
      357.9,
      122.5,
      884.2,
      11.5,
      103.4,
      43.3,
      473.1,
      99.7,
      719.5,
      11.4,
      108.7,
      21.7,
      237.0,
      160.6,
      1159.9,
      6.7,
      74.1,
      22.4,
      245.0,
      89.0,
      642.9,
      9.8,
      94.1,
      33.4,
      365.0,
      115.2,
      832.1,
      13.1,
      101.3,
      38.0,
      415.5,
      131.2,
      947.4,
      12.3,
      111.3,
      46.8,
      511.5,
      131.4,
      948.6,
      13.3,
      117.8,
      61.9,
      676.0,
      106.9,
      771.9,
      13.2,
      123.8,
      31.0,
      338.6,
      172.3,
      1244.4,
    ],
    [
      47,
      "Virginia",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      13.3,
      50.1,
      28.5,
      311.5,
      47.6,
      343.7,
      8.2,
      23.8,
      15.1,
      164.7,
      23.6,
      170.1,
      12.4,
      38.5,
      18.3,
      199.7,
      29.0,
      209.4,
      14.5,
      48.8,
      23.6,
      257.6,
      42.7,
      308.4,
      14.4,
      59.2,
      35.5,
      388.3,
      48.2,
      347.8,
      15.2,
      64.9,
      27.7,
      302.5,
      60.2,
      434.6,
      17.6,
      73.9,
      39.6,
      432.3,
      64.6,
      466.2,
      5.6,
      18.5,
      9.9,
      107.7,
      18.0,
      129.6,
      8.2,
      23.5,
      14.7,
      160.5,
      23.2,
      167.8,
      11.0,
      25.2,
      16.7,
      182.7,
      26.5,
      191.0,
      10.2,
      27.8,
      20.6,
      224.9,
      26.5,
      191.3,
      11.1,
      29.4,
      27.2,
      297.2,
      21.6,
      155.7,
      11.0,
      30.8,
      13.6,
      148.9,
      34.8,
      250.9,
      8.5,
      29.8,
      11.9,
      130.5,
      22.1,
      159.6,
      12.4,
      37.9,
      17.8,
      194.5,
      28.6,
      206.6,
      16.6,
      40.7,
      20.3,
      221.4,
      32.6,
      235.2,
      15.5,
      44.8,
      24.9,
      272.6,
      32.6,
      235.5,
      16.9,
      47.4,
      33.0,
      360.3,
      26.5,
      191.6,
      16.7,
      49.8,
      16.5,
      180.5,
      42.8,
      308.9,
      9.9,
      37.8,
      15.4,
      168.4,
      32.6,
      235.1,
      14.5,
      48.0,
      23.0,
      251.0,
      42.1,
      304.3,
      19.4,
      51.7,
      26.1,
      285.6,
      48.0,
      346.5,
      18.1,
      56.8,
      32.2,
      351.7,
      48.1,
      346.9,
      19.7,
      60.1,
      42.5,
      464.8,
      39.1,
      282.3,
      19.6,
      63.2,
      21.3,
      232.8,
      63.0,
      455.1,
      9.8,
      45.9,
      23.2,
      253.9,
      36.7,
      265.1,
      14.4,
      58.3,
      34.6,
      378.4,
      47.5,
      343.1,
      19.2,
      62.7,
      39.4,
      430.7,
      54.1,
      390.7,
      17.9,
      68.9,
      48.5,
      530.2,
      54.2,
      391.2,
      19.5,
      72.9,
      64.1,
      700.8,
      44.1,
      318.3,
      19.4,
      76.6,
      32.1,
      351.0,
      71.1,
      513.1,
      10.4,
      50.3,
      18.1,
      197.8,
      45.9,
      331.3,
      15.2,
      63.9,
      27.0,
      294.7,
      59.4,
      428.8,
      20.3,
      68.7,
      30.7,
      335.5,
      67.6,
      488.2,
      19.0,
      75.6,
      37.8,
      413.0,
      67.7,
      488.8,
      20.7,
      80.0,
      49.9,
      545.8,
      55.1,
      397.8,
      20.5,
      84.0,
      25.0,
      273.4,
      88.8,
      641.2,
      12.0,
      57.3,
      25.9,
      282.6,
      49.2,
      355.4,
      17.6,
      72.8,
      38.5,
      421.2,
      63.7,
      460.0,
      23.5,
      78.3,
      43.9,
      479.4,
      72.5,
      523.7,
      22.0,
      86.1,
      54.0,
      590.2,
      72.6,
      524.4,
      23.9,
      91.1,
      71.4,
      780.0,
      59.1,
      426.8,
      23.7,
      95.7,
      35.7,
      390.7,
      95.3,
      687.9,
    ],
    [
      48,
      "Washington",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.0,
      32.4,
      24.0,
      262.3,
      59.2,
      427.4,
      4.9,
      15.4,
      12.7,
      138.7,
      29.3,
      211.5,
      7.5,
      24.9,
      15.4,
      168.1,
      36.1,
      260.4,
      8.7,
      31.6,
      19.8,
      216.9,
      53.1,
      383.6,
      8.7,
      38.3,
      29.9,
      327.0,
      59.9,
      432.5,
      9.2,
      42.0,
      23.3,
      254.7,
      74.9,
      540.5,
      10.6,
      47.8,
      33.3,
      364.0,
      80.3,
      579.9,
      3.4,
      11.9,
      8.3,
      90.7,
      22.3,
      161.2,
      4.9,
      15.2,
      12.4,
      135.2,
      28.9,
      208.7,
      6.6,
      16.3,
      14.1,
      153.8,
      32.9,
      237.6,
      6.2,
      17.9,
      17.3,
      189.4,
      32.9,
      237.9,
      6.7,
      19.0,
      22.9,
      250.3,
      26.8,
      193.6,
      6.6,
      19.9,
      11.5,
      125.4,
      43.2,
      312.1,
      5.1,
      19.3,
      10.1,
      109.9,
      27.5,
      198.5,
      7.5,
      24.5,
      15.0,
      163.8,
      35.6,
      256.9,
      10.0,
      26.4,
      17.1,
      186.5,
      40.5,
      292.5,
      9.3,
      29.0,
      21.0,
      229.6,
      40.6,
      292.9,
      10.1,
      30.7,
      27.8,
      303.4,
      33.0,
      238.3,
      10.1,
      32.2,
      13.9,
      152.0,
      53.2,
      384.2,
      6.0,
      24.5,
      13.0,
      141.8,
      40.5,
      292.4,
      8.7,
      31.1,
      19.3,
      211.3,
      52.4,
      378.5,
      11.7,
      33.4,
      22.0,
      240.5,
      59.7,
      430.9,
      10.9,
      36.7,
      27.1,
      296.1,
      59.8,
      431.5,
      11.9,
      38.9,
      35.8,
      391.4,
      48.6,
      351.1,
      11.8,
      40.8,
      17.9,
      196.0,
      78.4,
      566.0,
      5.9,
      29.7,
      19.6,
      213.8,
      45.7,
      329.7,
      8.6,
      37.7,
      29.2,
      318.6,
      59.1,
      426.7,
      11.5,
      40.5,
      33.2,
      362.7,
      67.3,
      485.9,
      10.8,
      44.6,
      40.9,
      446.5,
      67.4,
      486.5,
      11.7,
      47.2,
      54.0,
      590.1,
      54.8,
      395.9,
      11.6,
      49.5,
      27.0,
      295.6,
      88.4,
      638.2,
      6.3,
      32.5,
      15.2,
      166.6,
      57.1,
      412.0,
      9.1,
      41.3,
      22.7,
      248.2,
      73.9,
      533.3,
      12.2,
      44.5,
      25.8,
      282.5,
      84.1,
      607.1,
      11.4,
      48.9,
      31.8,
      347.8,
      84.2,
      607.9,
      12.4,
      51.7,
      42.1,
      459.7,
      68.5,
      494.7,
      12.3,
      54.3,
      21.1,
      230.2,
      110.5,
      797.5,
      7.2,
      37.1,
      21.8,
      238.0,
      61.2,
      442.0,
      10.6,
      47.1,
      32.5,
      354.7,
      79.2,
      572.1,
      14.1,
      50.6,
      36.9,
      403.7,
      90.2,
      651.4,
      13.2,
      55.7,
      45.5,
      497.0,
      90.3,
      652.2,
      14.4,
      58.9,
      60.1,
      656.9,
      73.5,
      530.8,
      14.3,
      61.9,
      30.1,
      329.0,
      118.5,
      855.6,
    ],
    [
      49,
      "West Virginia",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      9.0,
      81.1,
      50.1,
      547.5,
      67.3,
      485.9,
      5.6,
      38.6,
      26.5,
      289.6,
      33.3,
      240.4,
      8.4,
      62.3,
      32.1,
      351.0,
      41.0,
      296.0,
      9.9,
      79.0,
      41.4,
      452.8,
      60.4,
      436.1,
      9.8,
      95.8,
      62.5,
      682.7,
      68.1,
      491.7,
      10.3,
      105.1,
      48.7,
      531.7,
      85.1,
      614.4,
      12.0,
      119.7,
      69.5,
      759.9,
      91.3,
      659.2,
      3.8,
      29.9,
      17.3,
      189.3,
      25.4,
      183.3,
      5.6,
      38.0,
      25.8,
      282.1,
      32.9,
      237.2,
      7.4,
      40.9,
      29.4,
      321.1,
      37.4,
      270.1,
      6.9,
      44.9,
      36.2,
      395.4,
      37.5,
      270.4,
      7.5,
      47.5,
      47.8,
      522.5,
      30.5,
      220.1,
      7.5,
      49.9,
      23.9,
      261.7,
      49.1,
      354.8,
      5.8,
      48.3,
      21.0,
      229.5,
      31.3,
      225.7,
      8.4,
      61.3,
      31.3,
      342.0,
      40.5,
      292.1,
      11.2,
      66.0,
      35.6,
      389.2,
      46.1,
      332.5,
      10.5,
      72.5,
      43.8,
      479.2,
      46.1,
      333.0,
      11.4,
      76.7,
      58.0,
      633.4,
      37.5,
      271.0,
      11.3,
      80.6,
      29.0,
      317.2,
      60.5,
      436.8,
      6.7,
      61.2,
      27.1,
      296.0,
      46.0,
      332.4,
      9.8,
      77.8,
      40.4,
      441.2,
      59.6,
      430.3,
      13.1,
      83.6,
      45.9,
      502.1,
      67.8,
      489.9,
      12.3,
      92.0,
      56.6,
      618.2,
      67.9,
      490.5,
      13.4,
      97.3,
      74.8,
      817.0,
      55.3,
      399.2,
      13.3,
      102.2,
      37.4,
      409.2,
      89.1,
      643.5,
      6.7,
      74.3,
      40.8,
      446.4,
      51.9,
      374.8,
      9.7,
      94.3,
      60.9,
      665.2,
      67.2,
      485.1,
      13.0,
      101.5,
      69.3,
      757.1,
      76.5,
      552.3,
      12.2,
      111.6,
      85.3,
      932.1,
      76.6,
      553.1,
      13.2,
      118.0,
      112.7,
      1231.9,
      62.3,
      450.1,
      13.1,
      124.0,
      56.5,
      617.0,
      100.5,
      725.5,
      7.1,
      81.5,
      31.8,
      347.7,
      64.9,
      468.4,
      10.3,
      103.5,
      47.4,
      518.1,
      84.0,
      606.2,
      13.8,
      111.3,
      54.0,
      589.7,
      95.6,
      690.2,
      12.9,
      122.4,
      66.4,
      726.0,
      95.7,
      691.1,
      14.0,
      129.5,
      87.8,
      959.5,
      77.9,
      562.4,
      13.9,
      136.0,
      44.0,
      480.6,
      125.6,
      906.6,
      8.2,
      92.8,
      45.5,
      496.9,
      69.6,
      502.5,
      11.9,
      117.8,
      67.7,
      740.4,
      90.1,
      650.4,
      15.9,
      126.7,
      77.1,
      842.7,
      102.6,
      740.5,
      14.9,
      139.4,
      94.9,
      1037.5,
      102.7,
      741.5,
      16.2,
      147.4,
      125.5,
      1371.2,
      83.6,
      603.4,
      16.1,
      154.9,
      62.8,
      686.8,
      134.7,
      972.6,
    ],
    [
      50,
      "Wisconsin",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      10.4,
      66.7,
      41.6,
      454.6,
      67.3,
      485.9,
      6.4,
      31.7,
      22.0,
      240.4,
      33.3,
      240.4,
      9.7,
      51.2,
      26.7,
      291.4,
      41.0,
      296.0,
      11.4,
      65.0,
      34.4,
      376.0,
      60.4,
      436.1,
      11.3,
      78.8,
      51.9,
      566.8,
      68.1,
      491.7,
      11.9,
      86.4,
      40.4,
      441.5,
      85.1,
      614.4,
      13.8,
      98.4,
      57.7,
      631.0,
      91.3,
      659.2,
      4.4,
      24.6,
      14.4,
      157.2,
      25.4,
      183.3,
      6.4,
      31.2,
      21.4,
      234.3,
      32.9,
      237.2,
      8.6,
      33.6,
      24.4,
      266.6,
      37.4,
      270.1,
      8.0,
      37.0,
      30.0,
      328.3,
      37.5,
      270.4,
      8.7,
      39.1,
      39.7,
      433.9,
      30.5,
      220.1,
      8.6,
      41.1,
      19.9,
      217.3,
      49.1,
      354.8,
      6.6,
      39.7,
      17.4,
      190.6,
      31.3,
      225.7,
      9.7,
      50.4,
      26.0,
      284.0,
      40.5,
      292.1,
      13.0,
      54.3,
      29.6,
      323.2,
      46.1,
      332.5,
      12.1,
      59.7,
      36.4,
      397.9,
      46.1,
      333.0,
      13.2,
      63.1,
      48.1,
      525.9,
      37.5,
      271.0,
      13.1,
      66.3,
      24.1,
      263.4,
      60.5,
      436.8,
      7.8,
      50.4,
      22.5,
      245.8,
      46.0,
      332.4,
      11.3,
      64.0,
      33.5,
      366.3,
      59.6,
      430.3,
      15.2,
      68.8,
      38.1,
      416.9,
      67.8,
      489.9,
      14.2,
      75.6,
      47.0,
      513.3,
      67.9,
      490.5,
      15.4,
      80.0,
      62.1,
      678.4,
      55.3,
      399.2,
      15.3,
      84.1,
      31.1,
      339.8,
      89.1,
      643.5,
      7.7,
      61.1,
      33.9,
      370.6,
      51.9,
      374.8,
      11.2,
      77.6,
      50.5,
      552.3,
      67.2,
      485.1,
      15.0,
      83.5,
      57.5,
      628.6,
      76.5,
      552.3,
      14.0,
      91.8,
      70.8,
      774.0,
      76.6,
      553.1,
      15.3,
      97.1,
      93.6,
      1022.9,
      62.3,
      450.1,
      15.1,
      102.0,
      46.9,
      512.3,
      100.5,
      725.5,
      8.1,
      67.0,
      26.4,
      288.7,
      64.9,
      468.4,
      11.9,
      85.1,
      39.4,
      430.2,
      84.0,
      606.2,
      15.9,
      91.5,
      44.8,
      489.6,
      95.6,
      690.2,
      14.8,
      100.6,
      55.2,
      602.9,
      95.7,
      691.1,
      16.2,
      106.5,
      72.9,
      796.7,
      77.9,
      562.4,
      16.0,
      111.9,
      36.5,
      399.1,
      125.6,
      906.6,
      9.4,
      76.3,
      37.7,
      412.6,
      69.6,
      502.5,
      13.8,
      96.9,
      56.3,
      614.8,
      90.1,
      650.4,
      18.4,
      104.2,
      64.0,
      699.7,
      102.6,
      740.5,
      17.2,
      114.6,
      78.8,
      861.5,
      102.7,
      741.5,
      18.7,
      121.3,
      104.2,
      1138.6,
      83.6,
      603.4,
      18.5,
      127.4,
      52.2,
      570.3,
      134.7,
      972.6,
    ],
    [
      51,
      "Wyoming",
      10.6,
      57.8,
      31.2,
      341.0,
      67.3,
      485.9,
      8.3,
      65.2,
      44.6,
      487.4,
      67.3,
      485.9,
      5.1,
      31.0,
      23.6,
      257.8,
      33.3,
      240.4,
      7.8,
      50.1,
      28.6,
      312.5,
      41.0,
      296.0,
      9.1,
      63.5,
      36.9,
      403.1,
      60.4,
      436.1,
      9.0,
      77.0,
      55.6,
      607.7,
      68.1,
      491.7,
      9.5,
      84.5,
      43.3,
      473.4,
      85.1,
      614.4,
      11.0,
      96.2,
      61.9,
      676.5,
      91.3,
      659.2,
      3.5,
      24.0,
      15.4,
      168.5,
      25.4,
      183.3,
      5.1,
      30.5,
      23.0,
      251.2,
      32.9,
      237.2,
      6.8,
      32.8,
      26.2,
      285.9,
      37.4,
      270.1,
      6.4,
      36.1,
      32.2,
      352.0,
      37.5,
      270.4,
      6.9,
      38.2,
      42.6,
      465.2,
      30.5,
      220.1,
      6.9,
      40.1,
      21.3,
      233.0,
      49.1,
      354.8,
      5.3,
      38.8,
      18.7,
      204.3,
      31.3,
      225.7,
      7.7,
      49.3,
      27.9,
      304.4,
      40.5,
      292.1,
      10.3,
      53.0,
      31.7,
      346.5,
      46.1,
      332.5,
      9.7,
      58.3,
      39.0,
      426.6,
      46.1,
      333.0,
      10.5,
      61.7,
      51.6,
      563.8,
      37.5,
      271.0,
      10.4,
      64.8,
      25.8,
      282.4,
      60.5,
      436.8,
      6.2,
      49.2,
      24.1,
      263.5,
      46.0,
      332.4,
      9.0,
      62.5,
      35.9,
      392.7,
      59.6,
      430.3,
      12.1,
      67.2,
      40.9,
      447.0,
      67.8,
      489.9,
      11.3,
      73.9,
      50.4,
      550.3,
      67.9,
      490.5,
      12.3,
      78.2,
      66.6,
      727.3,
      55.3,
      399.2,
      12.2,
      82.2,
      33.3,
      364.3,
      89.1,
      643.5,
      6.1,
      59.7,
      36.4,
      397.4,
      51.9,
      374.8,
      8.9,
      75.8,
      54.2,
      592.1,
      67.2,
      485.1,
      12.0,
      81.6,
      61.7,
      674.0,
      76.5,
      552.3,
      11.2,
      89.7,
      75.9,
      829.8,
      76.6,
      553.1,
      12.2,
      94.9,
      100.3,
      1096.6,
      62.3,
      450.1,
      12.1,
      99.7,
      50.3,
      549.3,
      100.5,
      725.5,
      6.5,
      65.5,
      28.3,
      309.5,
      64.9,
      468.4,
      9.5,
      83.2,
      42.2,
      461.2,
      84.0,
      606.2,
      12.7,
      89.5,
      48.0,
      525.0,
      95.6,
      690.2,
      11.8,
      98.4,
      59.1,
      646.3,
      95.7,
      691.1,
      12.9,
      104.1,
      78.2,
      854.2,
      77.9,
      562.4,
      12.8,
      109.3,
      39.1,
      427.9,
      125.6,
      906.6,
      7.5,
      74.6,
      40.5,
      442.3,
      69.6,
      502.5,
      11.0,
      94.7,
      60.3,
      659.1,
      90.1,
      650.4,
      14.7,
      101.9,
      68.6,
      750.2,
      102.6,
      740.5,
      13.7,
      112.0,
      84.5,
      923.6,
      102.7,
      741.5,
      14.9,
      118.5,
      111.7,
      1220.7,
      83.6,
      603.4,
      14.8,
      124.5,
      55.9,
      611.4,
      134.7,
      972.6,
    ],
  ];

  //Module Navigation//
  function handleChange(newValue) {
    setTimeout(function () {
      setmodulePosition(newValue);
    }, 1000);
  }
  const [modulePosition, setmodulePosition] = useState("Commercial1");

  var currentStep = "";

  //Customer Data Function (checkout) //
  function customerChange(e) {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  }

  const [customerData, setCustomerData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    billingAddress: "",
    billingSuite: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    shippingAddress: "",
    shippingSuite: "",
    shippingCity: "",
    shippingState: "",
    shippingZip: "",
    email: "",
    phone: "",
    ext: "",
    referral: "",
  });

  //Main Data function that updates state//
  function dataChange(e) {
    setcommercialData({
      ...commercialData,
      [e.target.name]: e.target.value,
    });
  }

  //Initialize Main Data States//
  const [commercialData, setcommercialData] = useState({
    commercialState: "0",
    priceType: "yearly",
    uetGreen: "false",
    userElectricity: 0,
    userElectricityUnit: "kWh",
    userPropane: 0,
    userPropaneUnit: "Gal",
    userHeatingOil: 0,
    userHeatingOilUnit: "Gal",
    userNaturalGas: 0,
    userNaturalGasUnit: "Therm",
    //Car 1 Residential Fleet 1 Commercial//
    firstCarMpg: 1,
    firstCarFuel: "",
    firstCarMpy: 1,
    firstfleetCount: 1,
    //Car 2 Residential Fleet 2 Commercial//
    secondCarMpg: 1,
    secondCarFuel: "",
    secondCarMpy: 1,
    secondfleetCount: 1,
    //Car 3 Residential Fleet 3 Commercial//
    thirdCarMpg: 1,
    thirdCarFuel: "",
    thirdCarMpy: 1,
    thirdfleetCount: 1,
    //Car 4 Residential Fleet 4 Commercial//
    fourthCarMpg: 1,
    fourthCarFuel: "",
    fourthCarMpy: 1,
    fourthfleetCount: 1,
  });

  //Vehicle Conditions//

  var firstCarCarbon = 0;
  var secondCarCarbon = 0;
  var thirdCarCarbon = 0;
  var fourthCarCarbon = 0;

  //First Car//
  switch (commercialData.firstCarFuel) {
    case "Gas":
      if (
        isFinite(
          (commercialData.firstCarMpy / commercialData.firstCarMpg) * 0.0089
        )
      ) {
        firstCarCarbon =
          (commercialData.firstCarMpy / commercialData.firstCarMpg) * 0.0089;
      } else {
        firstCarCarbon = 0;
      }
      break;
    case "Diesel":
      if (
        isFinite(
          (commercialData.firstCarMpy / commercialData.firstCarMpg) * 0.01018
        )
      ) {
        firstCarCarbon =
          (commercialData.firstCarMpy / commercialData.firstCarMpg) * 0.01018;
      } else {
        firstCarCarbon = 0;
      }
      firstCarCarbon =
        (commercialData.firstCarMpy / commercialData.firstCarMpg) * 0.01018;
      break;
    case "Electric":
      firstCarCarbon = 0;
      break;
    case "CNG":
      if (
        isFinite(
          (commercialData.firstCarMpy / commercialData.firstCarMpg) *
            (1.14 / 10) *
            0.05302
        )
      ) {
        firstCarCarbon =
          (commercialData.firstCarMpy / commercialData.firstCarMpg) *
          (1.14 / 10) *
          0.05302;
      } else {
        firstCarCarbon = 0;
      }

      break;
  }
  //Second Car//
  switch (commercialData.secondCarFuel) {
    case "Gas":
      if (
        isFinite(
          (commercialData.secondCarMpy / commercialData.secondCarMpg) * 0.0089
        )
      ) {
        secondCarCarbon =
          (commercialData.secondCarMpy / commercialData.secondCarMpg) * 0.0089;
      } else {
        secondCarCarbon = 0;
      }
      break;
    case "Diesel":
      if (
        isFinite(
          (commercialData.secondCarMpy / commercialData.secondCarMpg) * 0.01018
        )
      ) {
        secondCarCarbon =
          (commercialData.secondCarMpy / commercialData.secondCarMpg) * 0.01018;
      } else {
        secondCarCarbon = 0;
      }

      break;
    case "Electric":
      secondCarCarbon = 0;
      break;
    case "CNG":
      if (
        isFinite(
          (commercialData.secondCarMpy / commercialData.secondCarMpg) *
            (1.14 / 10) *
            0.05302
        )
      ) {
        secondCarCarbon =
          (commercialData.secondCarMpy / commercialData.secondCarMpg) *
          (1.14 / 10) *
          0.05302;
      } else {
        secondCarCarbon = 0;
      }

      break;
  }
  //Third Car//
  switch (commercialData.thirdCarFuel) {
    case "Gas":
      if (
        isFinite(
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) * 0.0089
        )
      ) {
        thirdCarCarbon =
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) * 0.0089;
      } else {
        thirdCarCarbon = 0;
      }
      break;
    case "Diesel":
      if (
        isFinite(
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) * 0.01018
        )
      ) {
        thirdCarCarbon =
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) * 0.01018;
      } else {
        thirdCarCarbon = 0;
      }

      break;
    case "Electric":
      thirdCarCarbon = 0;
      break;
    case "CNG":
      if (
        isFinite(
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) *
            (1.14 / 10) *
            0.05302
        )
      ) {
        thirdCarCarbon =
          (commercialData.thirdCarMpy / commercialData.thirdCarMpg) *
          (1.14 / 10) *
          0.05302;
      } else {
        thirdCarCarbon = 0;
      }

      break;
  }
  //Fourth Car//
  switch (commercialData.fourthCarFuel) {
    case "Gas":
      if (
        isFinite(
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) * 0.0089
        )
      ) {
        fourthCarCarbon =
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) * 0.0089;
      } else {
        fourthCarCarbon = 0;
      }
      break;
    case "Diesel":
      if (
        isFinite(
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) * 0.01018
        )
      ) {
        fourthCarCarbon =
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) * 0.01018;
      } else {
        fourthCarCarbon = 0;
      }

      break;
    case "Electric":
      fourthCarCarbon = 0;
      break;
    case "CNG":
      if (
        isFinite(
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) *
            (1.14 / 10) *
            0.05302
        )
      ) {
        fourthCarCarbon =
          (commercialData.fourthCarMpy / commercialData.fourthCarMpg) *
          (1.14 / 10) *
          0.05302;
      } else {
        fourthCarCarbon = 0;
      }

      break;
  }

  var vTons = Number(
    (
      firstCarCarbon * commercialData.firstfleetCount +
      secondCarCarbon * commercialData.secondfleetCount +
      thirdCarCarbon * commercialData.thirdfleetCount +
      fourthCarCarbon * commercialData.fourthfleetCount
    ).toFixed(3)
  );

  var userElectricityConverted = 0;
  var userNGConverted = 0;

  //Energy Conversions Electric//
  switch (commercialData.userElectricityUnit) {
    case "MWh":
      userElectricityConverted = Math.abs(commercialData.userElectricity * 1);
      break;
    case "kWh":
      userElectricityConverted = Math.abs(
        commercialData.userElectricity * 0.001
      );
      break;
  }
  //Energy Conversions NG//
  switch (commercialData.userNaturalGasUnit) {
    case "Therm":
      userNGConverted = Math.abs(commercialData.userNaturalGas * 0.1);
      break;
    case "CCF":
      userNGConverted = Math.abs(commercialData.userNaturalGas * 0.1037);
      break;
    case "MCF":
      userNGConverted = Math.abs(commercialData.userNaturalGas * 1.037);
      break;
  }

  var usageElec = userElectricityConverted / 0.29;
  var usageHeating = userNGConverted;

  //New formula//
  var usageTons = Number(((usageElec + usageHeating) * 0.05302).toFixed(2));
  var totalOffsetYears = Number(
    ((usageTons + vTons) * carbonOffset).toFixed(2)
  );

  console.log(customerData);
  console.log(vTons);

  switch (modulePosition) {
    case "Commercial1":
      currentStep = (
        <Commercial1
          onChange={handleChange}
          addData={dataChange}
          data={commercialData}
        />
      );
      break;
    case "Commercial2":
      currentStep = (
        <Commercial2
          onChange={handleChange}
          addData={dataChange}
          data={commercialData}
          electricity={userElectricityConverted}
          naturalGas={userNGConverted}
        />
      );
      break;
    case "Commercial3":
      currentStep = (
        <Commercial3
          onChange={handleChange}
          addData={dataChange}
          data={commercialData}
          priceYear={totalOffsetYears}
          usage={Number((usageTons + vTons).toFixed(2))}
        />
      );
      break;
    case "PreCheckout":
      currentStep = (
        <PreCheckout
          onChange={handleChange}
          addData={dataChange}
          addCustomerData={customerChange}
          data={commercialData}
          customer={customerData}
          priceYear={totalOffsetYears}
          usage={Number((usageTons + vTons).toFixed(2))}
        />
      );
      break;
    case "Checkout":
      currentStep = (
        <Checkout
          onChange={handleChange}
          addData={dataChange}
          addCustomerData={customerChange}
          data={commercialData}
          customer={customerData}
          priceYear={totalOffsetYears}
          usage={Number((usageTons + vTons).toFixed(2))}
        />
      );
      break;
  }
  return (
    <>
      {modulePosition === "PreCheckout" || modulePosition === "Checkout" ? (
        <div>
          <main class="pb-20 bg-white">
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Go Green Credits | Commercial Offset Calculator
              </title>

              <meta
                name="description"
                content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
              />
              <link
                rel="icon"
                href={Favicon}
              />
            </Helmet>
            <Header />

            {currentStep}
          </main>
          <Footer />
        </div>
      ) : (
        <div>
          <main
            class="pb-20 bg-cover"
            style={{ backgroundImage: `url(${CommercialBG})` }}
          >
            <Helmet>
              <meta charSet="utf-8" />
              <title>
                Go Green Credits | Commercial Offset Calculator
              </title>

              <meta
                name="description"
                content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
              />
              <link
                rel="icon"
                href={Favicon}
              />
            </Helmet>
            <Header />

            {currentStep}
          </main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default OffsetCommercial;
