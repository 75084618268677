import React, { useState } from "react";

const Commercial3 = (props) => {
  const [carFleetCount, setcarFleetCount] = useState(1);

  const fleetCounter = () => setcarFleetCount(carFleetCount + 1);

  return (
    <div class="Commercial 3">
      {" "}
      <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-between items-start">
            <div class="md:w-1/2">
              <nav class="opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Commercial1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Commercial
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Commercial2";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Energy
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white hover:text-gray-200">
                        Transportation
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Commercial
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                Enter the details below for company-owned vehicles and/or
                business-related transportation.
              </p>{" "}
              <div class="w-full car1 mt-8 pb-4 space-y-6 ">
                <div>
                  <div class="mt-12 flex items-center">
                    <svg
                      class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M3 18h-2c-.552 0-1-.448-1-1v-2h15v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-4-2.8h-14v-10c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v10zm3-6v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z" />
                    </svg>
                    <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                      Business related transportation-Driving
                    </h1>
                  </div>

                  {carFleetCount == "1" ||
                  carFleetCount == "2" ||
                  carFleetCount == "3" ||
                  carFleetCount == "4" ? (
                    <div class="fleet1">
                      {props.data.firstCarFuel === "Electric" ? (
                        <div class="fleet 1">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="firstCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="firstCarMpg"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="firstCarMpy"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="firstfleetCount"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div class="car 1">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="firstCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="firstCarMpg"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="firstCarMpy"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="firstfleetCount"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      )}
                      <div class="mt-4 rounded-md ">
                        <button
                          onClick={fleetCounter}
                          class="text-gray-200 underline font-medium text-sm hover:opacity-70"
                        >
                          + Add move vehicles profiles
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {carFleetCount == "2" ||
                  carFleetCount == "3" ||
                  carFleetCount == "4" ? (
                    <div class="fleet2">
                      <div class="border-t pt-10 mt-12 flex items-center">
                        <svg
                          class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M3 18h-2c-.552 0-1-.448-1-1v-2h15v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-4-2.8h-14v-10c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v10zm3-6v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z" />
                        </svg>
                        <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                          Business related transportation-Driving
                        </h1>
                      </div>
                      {props.data.secondCarFuel === "Electric" ? (
                        <div class="fleet 2">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="secondCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="secondCarMpg"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="secondCarMpy"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="secondfleetCount"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div class="fleet 2">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="secondCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="secondCarMpg"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="secondCarMpy"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="secondfleetCount"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      )}
                      <div class="mt-4 rounded-md ">
                        <button
                          onClick={fleetCounter}
                          class="text-gray-200 underline font-medium text-sm hover:opacity-70"
                        >
                          + Add move vehicles profiles
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {carFleetCount == "3" || carFleetCount == "4" ? (
                    <div class="fleet3">
                      <div class="border-t pt-10 mt-12 flex items-center">
                        <svg
                          class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M3 18h-2c-.552 0-1-.448-1-1v-2h15v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-4-2.8h-14v-10c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v10zm3-6v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z" />
                        </svg>
                        <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                          Business related transportation-Driving
                        </h1>
                      </div>
                      {props.data.thirdCarFuel === "Electric" ? (
                        <div class="fleet 3">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="thirdCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="thirdCarMpg"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="thirdCarMpy"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="thirdfleetCount"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div class="fleet 3">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="thirdCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="thirdCarMpg"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="thirdCarMpy"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="thirdfleetCount"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      )}
                      <div class="mt-4 rounded-md ">
                        <button
                          onClick={fleetCounter}
                          class="text-gray-200 underline font-medium text-sm hover:opacity-70"
                        >
                          + Add move vehicles profiles
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {carFleetCount == "4" ? (
                    <div class="fleet4">
                      <div class="border-t pt-10 mt-12 flex items-center">
                        <svg
                          class="-mt-2 flex-shrink-0 text-emerald-200 h-8 w-8"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M3 18h-2c-.552 0-1-.448-1-1v-2h15v-9h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.121-.728 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-6c0 1.656-1.344 3-3 3s-3-1.344-3-3zm3-1.2c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm12 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-4-2.8h-14v-10c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v10zm3-6v3h4.715l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.42z" />
                        </svg>
                        <h1 class="text-2xl ml-2 font-bold text-emerald-200">
                          Business related transportation-Driving
                        </h1>
                      </div>
                      {props.data.fourthCarFuel === "Electric" ? (
                        <div class="fleet 4">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="fourthCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="fourthCarMpg"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="fourthCarMpy"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 opacity-50 relative rounded-md">
                            <input
                              type="number"
                              name="fourthfleetCount"
                              disabled
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      ) : (
                        <div class="fleet 4">
                          <div class="mt-6 relative rounded-md">
                            <select
                              name="fourthCarFuel"
                              onChange={props.addData}
                              class="mt-8 block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                            >
                              <option value="empty" default selected>
                                Fuel Type
                              </option>
                              <option value="Gas">Gasoline/Hybrid</option>

                              <option value="Diesel">Diesel</option>
                              <option value="Electric">Electric</option>
                            </select>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="fourthCarMpg"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated Miles/Gallon"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="fourthCarMpy"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="Estimated miles driven/year"
                            ></input>
                          </div>
                          <div class="mt-4 relative rounded-md">
                            <input
                              type="number"
                              name="fourthfleetCount"
                              onChange={props.addData}
                              onWheel={(e) => e.target.blur()}
                              class="block w-full pl-3 text-lg text-gray-700 py-4 border border-emerald-700 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                              placeholder="# Of Vehicles that fit this Profile"
                            ></input>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div class="button-group border-t pt-8 mt-8 md:flex justify-start ">
                <button
                  onClick={() => {
                    const newPosition = "Commercial2";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 rounded-md flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-gradient-to-r from-fadedGreen to-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 "
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    const newPosition = "PreCheckout";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                >
                  Complete
                </button>
              </div>
            </div>
            <form class="checkout mt-5 md:pl-10 md:w-2/5 md:sticky md:top-5 ">
              <section class="mt-16 bg-gray-50 border border-emerald-500 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                {props.data.uetGreen === "true" ? (
                  <span class="mb-5 flex justify-center w-1/2 mx-auto py-1 rounded-full text-xs font-medium bg-emerald-100 text-green-800">
                    {" "}
                    <svg
                      class="mr-2 h-5 text-green-800"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h4 class=" text-sm font-medium text-green-800 mb-0">
                      Green Gas Customer
                    </h4>
                  </span>
                ) : (
                  <div></div>
                )}
                <div class="flex justify-center">
                  {" "}
                  <svg
                    class="h-7 md:h-8 text-emerald-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M24 24h-23v-16h6v-8h11v12h6v12zm-12-5h-3v4h3v-4zm4 0h-3v4h3v-4zm6 0h-2v4h2v-4zm-17 0h-2v4h2v-4zm11-5h-2v2h2v-2zm-5 0h-2v2h2v-2zm11 0h-2v2h2v-2zm-17 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm-6 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm5-4h-2v2h2v-2zm-5 0h-2v2h2v-2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h2 class="text-xl mt-1 ml-2 md:text-2xl text-emerald-600">
                    Carbon Footprint
                  </h2>
                </div>

                <dl class="mt-3 space-y-4">
                  <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                    Your purchase will eliminate{" "}
                    <span class="text-emerald-600 font-bold ">
                      {" "}
                      {props.usage} TONS
                    </span>{" "}
                    of greenhouse gases Annually
                  </p>
                  <div class="border-t border-gray-200 pt-8 flex items-center justify-center">
                    <fieldset class="">
                      {props.data.priceType === "yearly" ? (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>{" "}
                </dl>

                {props.usage === 0 ? (
                  <div class="mt-7">
                    <button
                      type="button"
                      class="w-full pointer-events-none opacity-50 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                ) : (
                  <div class="mt-7">
                    <button
                      onClick={() => {
                        const newPosition = "PreCheckout";
                        props.onChange(newPosition);
                      }}
                      type="button"
                      class="w-full py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                )}
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commercial3;
