import React, { useState } from "react";

const Commercial1 = (props) => {
  const [sectorForm, setSectorForm] = useState({
    bizSector: "",
    bizName: "",
    bizRepName: "",
    bizRepEmail: "",
    bizPhone: "",
  });

  const [SubmitDisabled, setSubmitDisabled] = useState(false);

  function handleChange(e) {
    setSectorForm({
      ...sectorForm,
      [e.target.name]: e.target.value,
    });
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    setSubmitDisabled(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Commercial Submission", ...sectorForm }),
    })
      .then(() => window.open("../../thank-you-general", "_self"))
      .catch(() => window.open("../Error", "_self"));

    e.preventDefault();
  };

  console.log(sectorForm);
  return (
    <div class="Commercial 1">
      {" "}
      <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-center items-center">
            <div class="md:w-1/2">
              <nav class="opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white hover:text-gray-200">
                        Commercial
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Commercial
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                This tool will calculate the carbon footprint of your business.
                To complete the calculator you will need to provide details
                about your business and utilities usage.
              </p>{" "}
              <div class="select state">
                <select
                  name="commercialState"
                  onChange={props.addData}
                  class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                >
                  <option value="0" default selected>
                    What State is your business located in?
                  </option>
                  <option disabled>------------------</option>
                  <option value="1">Alabama</option>
                  <option value="2">Alaska</option>
                  <option value="3">Arizona</option>
                  <option value="4">Arkansas</option>
                  <option value="5">California</option>
                  <option value="6">Colorado</option>
                  <option value="7">Connecticut</option>
                  <option value="8">Delaware</option>
                  <option value="9">District Of Columbia</option>
                  <option value="10">Florida</option>
                  <option value="11">Georgia</option>
                  <option value="12">Hawaii</option>
                  <option value="13">Idaho</option>
                  <option value="14">Illinois</option>
                  <option value="15">Indiana</option>
                  <option value="16">Iowa</option>
                  <option value="17">Kansas</option>
                  <option value="18">Kentucky</option>
                  <option value="19">Louisiana</option>
                  <option value="20">Maine</option>
                  <option value="21">Maryland</option>
                  <option value="22">Massachusetts</option>
                  <option value="23">Michigan</option>
                  <option value="24">Minnesota</option>
                  <option value="25">Mississippi</option>
                  <option value="26">Missouri</option>
                  <option value="27">Montana</option>
                  <option value="28">Nebraska</option>
                  <option value="29">Nevada</option>
                  <option value="30">New Hampshire</option>
                  <option value="31">New Jersey</option>
                  <option value="32">New Mexico</option>
                  <option value="33">New York</option>
                  <option value="34">North Carolina</option>
                  <option value="35">North Dakota</option>
                  <option value="36">Ohio</option>
                  <option value="37">Oklahoma</option>
                  <option value="38">Oregon</option>
                  <option value="39">Pennsylvania</option>
                  <option value="40">Rhode Island</option>
                  <option value="41">South Carolina</option>
                  <option value="42">South Dakota</option>
                  <option value="43">Tennessee</option>
                  <option value="44">Texas</option>
                  <option value="45">Utah</option>
                  <option value="46">Vermont</option>
                  <option value="47">Virginia</option>
                  <option value="48">Washington</option>
                  <option value="49">West Virginia</option>
                  <option value="50">Wisconsin</option>
                  <option value="51">Wyoming</option>
                </select>
              </div>
              <div class="sector">
                <select
                  name="bizSector"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                >
                  <option value="" default selected>
                    Business Sector
                  </option>
                  <option disabled>------------------</option>
                  <option value="congregations">Congregations</option>
                  <option value="gov-facilities">Gov. Facilities</option>
                  <option value="hotels">Hotels/Motels</option>
                  <option value="manufacturing">Manufacturing</option>
                  <option value="health">Medical/Health</option>
                  <option value="restaurant">Restaurant</option>
                  <option value="retail">Retail</option>
                  <option value="services">Services</option>
                  <option value="warehouse">Trucking and Warehousing</option>
                  <option value="wholesale">Wholesale</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {sectorForm.bizSector === "manufacturing" ||
              sectorForm.bizSector === "warehouse" ||
              sectorForm.bizSector === "other" ? (
                <div>
                  {" "}
                  <p class="mt-3 mb-7 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                    Manufacturing plants, industrial warehouses, and similar
                    types of larger commercial buildings produce high volumes of
                    CO2. For us to be able to accurately calculate the carbon
                    production of your facility we'll need additional
                    information. Click the link below to fill out an inquiry
                    form for your business.
                  </p>{" "}
                  <a href="/commercial-form-submission" class="">
                    <button class=" w-full mt-3 flex items-center justify-center px-10 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0">
                      <span class="">Commerical Inquiry </span>
                    </button>
                  </a>
                </div>
              ) : (
                <div></div>
              )}
              {props.data.commercialState !== "0" &&
              sectorForm.bizSector !== "" ? (
                <div>
                  {sectorForm.bizSector === "manufacturing" ||
                  sectorForm.bizSector === "warehouse" ||
                  sectorForm.bizSector === "other" ? (
                    <div class="button-group border-t pt-8 mt-8 md:flex justify-start">
                      <div class="md:w-1/2 rounded-md ">
                        <a
                          href="/"
                          class="flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-white bg-gradient-to-r from-fadedGreen to-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10"
                        >
                          Home
                        </a>
                      </div>
                      <button class="w-full md:w-1/2 pointer-events-none opacity-40 mt-3 flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-white bg-neonGreen opacity-40 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3">
                        Continue
                      </button>
                    </div>
                  ) : (
                    <div class="button-group border-t pt-8 mt-8 md:flex justify-start ">
                      <div class="md:w-1/2 rounded-md ">
                        <a
                          href="/"
                          class="flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-gradient-to-r from-fadedGreen to-neonGreen  hover:opacity-90 md:py-4 md:text-lg md:px-10"
                        >
                          Home
                        </a>
                      </div>
                      <button
                        onClick={() => {
                          const newPosition = "Commercial2";
                          props.onChange(newPosition);
                        }}
                        class="w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                      >
                        Continue
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div class="button-group border-t pt-8 mt-8 md:flex justify-start ">
                  <div class="md:w-1/2 rounded-md ">
                    <a
                      href="/"
                      class="flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-gradient-to-r from-fadedGreen to-neonGreen hover:opacity-90 md:py-4 md:text-lg md:px-10"
                    >
                      Home
                    </a>
                  </div>
                  <button class="w-full md:w-1/2 pointer-events-none opacity-40 mt-3 flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-white bg-neonGreen opacity-50 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3">
                    Continue
                  </button>
                </div>
              )}
            </div>
            <div class="pl-10 w-1/2  relative"></div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commercial1;
